import { Component, OnInit, Input } from '@angular/core';
import { MobileNavService } from '../services/mobile-nav.service';
import { LoginService } from '../services/login.service';
import { environment } from '../../environments/environment';

@Component({
  selector: 'app-side-nav-mobile',
  templateUrl: './side-nav-mobile.component.html',
  styleUrls: ['./side-nav-mobile.component.scss']
})
export class SideNavMobileComponent implements OnInit {
  navOpen: boolean = false;
  subNavOpen: Object = {
    bus: false,
    cust: false
  };
  environment = environment;

  logout(): void {
    this.loginService.logout();
  }

  closeMobileNav(): void {
    this.mobileNavService.navOpen=false;
  }

  get navIsOpen(): boolean {
    return this.mobileNavService["navOpen"];
  }

  setNavOpen(open: boolean): void {
    this.mobileNavService["navOpen"] = open;
  }

  // Not using the mobile nav submenus. Left this here in case I want to in the
  // future.

  // toggleSubNav(event): void {
  //   if(event.target.className.indexOf("side-nav-button") == -1) {
  //     return;
  //   }
  //   if(event.target.nextElementSibling.className.indexOf("mobile-submenu-open") == -1) {
  //     event.target.nextElementSibling.classList.add("mobile-submenu-open");
  //     this.subNavOpen[event.target.id] = true;
  //   } else {
  //     event.target.nextElementSibling.classList.remove("mobile-submenu-open");
  //     this.subNavOpen[event.target.id] = false;
  //   }
  // }

  constructor(
    private mobileNavService: MobileNavService,
    private loginService: LoginService
  ) { }

  ngOnInit() {
  }

}
