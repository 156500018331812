import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-dynamic-link',
  templateUrl: './dynamic-link.component.html',
  styleUrls: ['./dynamic-link.component.scss']
})
export class DynamicLinkComponent implements OnInit {
  @Input() linkAddress: string;
  @Input() content: string;

  constructor() { }

  ngOnInit() {
  }

}
