import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Title } from '@angular/platform-browser';
import { AutoconfirmatorService } from '../services/autoconfirmator.service';
import { environment } from '../../environments/environment';

@Component({
  selector: 'app-autoconfirmator',
  templateUrl: './autoconfirmator.component.html',
  styleUrls: ['./autoconfirmator.component.scss']
})
export class AutoconfirmatorComponent implements OnInit {
  massConfButtonText: string = "Initiate Mass Confirmation";
  confirmationAlreadySent: boolean = false;
  confirmationsLog: Array<Object>;
  outstanding: Array<Object>;
  usersWithoutEmail: Array<Object>;
  unsubscribedUsers: Array<Object>;
  bouncedUsers: Array<Object>;
  vehsWithDifferentOwnerPrice: Array<Object>;
  environment = environment;

  openVehicle(event: any, id: string): void {
    if(event["altKey"]) {
      window.open("/vehicledata/"+id, "_blank");
    }
    else {
      this.router.navigateByUrl("/vehicledata/"+id);
    }
  }

  openContact(event: any, id: string): void {
    if(event["altKey"]) {
      window.open("/contactdata/"+id, "_blank");
    }
    else {
      this.router.navigateByUrl("/contactdata/"+id);
    }
  }

  sendMassConfirmation(): void {
    if(this.confirmationAlreadySent) {return;}

    if(window.confirm("ATTENTION: You are about to send a mass e-mail confirmation for all buses' availability. Are you sure?")) {
      this.confirmationAlreadySent = true;
      this.massConfButtonText = "Processing...";
      this.autoconfirmatorService.sendMassConfirmation().subscribe(response => {
        this.massConfButtonText = "Sent "+response["processed"]+" E-Mails";
        if(response["errors"] && response["errors"].length) {
          console.log(response["errors"]);
        }
        this.refreshData();
      });
    }
  }

  refreshData(): void {
    this.autoconfirmatorService.confirmationsLog.subscribe(res => this.confirmationsLog = res);
    this.autoconfirmatorService.outstanding.subscribe(res => this.outstanding = res);
    this.autoconfirmatorService.usersWithoutEmail.subscribe(res => this.usersWithoutEmail = res);
    this.autoconfirmatorService.unsubscribedUsers.subscribe(res => this.unsubscribedUsers = res);
    this.autoconfirmatorService.bouncedUsers.subscribe(res => this.bouncedUsers = res);
    this.autoconfirmatorService.vehsWithDifferentOwnerPrice.subscribe(res => this.vehsWithDifferentOwnerPrice = res);
  }

  constructor(
    private titleService: Title,
    private autoconfirmatorService: AutoconfirmatorService,
    private router: Router
  ) { }

  ngOnInit() {
    this.titleService.setTitle("Autoconfirmator");
    this.refreshData();
  }

}
