import { Component, OnInit } from '@angular/core';
import { UpdateLightsService } from '../services/update-lights.service';

@Component({
  selector: 'app-update-lights',
  templateUrl: './update-lights.component.html',
  styleUrls: ['./update-lights.component.scss']
})
export class UpdateLightsComponent implements OnInit {

  get nextChangesComing(): boolean {
    return this.updateLightsService.nextChangesComing();
  }

  get pendingChanges(): number {
    return this.updateLightsService.pendingChanges;
  }

  constructor(private updateLightsService: UpdateLightsService) { }

  ngOnInit() {
  }

}
