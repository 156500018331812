import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { VehicleService } from '../../services/vehicle.service';

@Component({
  selector: 'app-supplier-purchaser-status',
  templateUrl: './supplier-purchaser-status.component.html',
  styleUrls: ['./supplier-purchaser-status.component.scss']
})
export class SupplierPurchaserStatusComponent implements OnInit {
  @Input() sps: Object = {};
  @Input() maxHeight: string;
  @Output() changeSps: EventEmitter<Object> = new EventEmitter<Object>();
  categories: Array<string> = [];

  handleChange(event: any): void {
    let cat = event.target.name;
    let val = event.target.value;
    this.changeSps.emit({
      "cat": cat,
      "value": val
    });
  }

  spsVal(cat: string, value: string): boolean {
    // The radio button calling this function is selected if either:
    // 1. this.sps exists and its value code for the given category is equal to
    // this button's value code, or
    // 2. this.sps does not exist, or this.sps is null for this category, and
    // this button's value code is equal to "N".
    return (this.sps && this.sps[cat] === value) ||
    ((!this.sps || !this.sps[cat]) && value === 'N');
  }

  constructor(
    private vehicleService: VehicleService
  ) { }

  ngOnInit() {
    this.vehicleService.vehicleCategories.subscribe(cats => this.categories = cats);
  }
}
