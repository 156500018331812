import { Component, OnInit, Input, ElementRef, ViewChild, AfterViewInit, ViewChildren, QueryList } from '@angular/core';
import { Router } from '@angular/router';
import { AutolinkerService } from '../../services/autolinker.service';
import { TasksService } from '../../services/tasks.service';
import { DatePipe } from '@angular/common';
import { environment } from '../../../environments/environment';

@Component({
  selector: 'app-tasker',
  templateUrl: './tasker.component.html',
  styleUrls: ['./tasker.component.scss']
})
export class TaskerComponent implements OnInit {
  @Input() type: string;
  users: Array<Object>;
  tasksArray: Array<Object>;
  tasksToDisplay: Array<Object> = [];
  @ViewChild('assignTo') assignTo: ElementRef;
  @ViewChild('task') task: ElementRef;
  @ViewChildren('opt') opts: QueryList<ElementRef>;
  selectedOption: string = "";
  environment = environment;

  assignTask(assignTo: string, taskField: any): void {
    localStorage.removeItem("taskAssignToU");
    localStorage.removeItem("taskText");
    this.tasksService.assignTask(assignTo, taskField["value"]).subscribe(() => {
      this.getTasks();
      taskField["value"] = "";
    });
  }

  removeTask(id: string, type: string): void {
    if(type === 'others') {
      this.tasksService.removeTask(id).subscribe(() => this.getTasks());
    }
    else {
      this.tasksService.markTaskDone(id).subscribe(() => this.getTasks());
    }
  }

  getTasks(): void {
    this.tasksService.getTasks(this.type).subscribe(tasks => {
      this.tasksArray = tasks;
      let taskTexts = [];
      for(let i = 0; i < tasks.length; i++) {
        taskTexts.push(tasks[i]["task"]);
      }

      let results = this.autolinkerService.autolink(taskTexts, this.elRef.nativeElement['localName']);

      // Promise.all(result).then(linkedTasks => {
      new Promise(async resolve => {
        let linkedTasks = [];
        for(let i = 0; i < results.length; i++) {
          let linkedTask = await results[i]();
          tasks[i]["task"] = linkedTask["text"];
          tasks[i]["links"] = linkedTask["links"];
          tasks[i]["order"] = linkedTask["order"];
          linkedTasks.push(tasks[i]);
        }
        resolve(linkedTasks);
      }).then(linkedTasks => {
        this.tasksToDisplay = [];
        for(let i = 0; i < linkedTasks["length"]; i++) {
          this.tasksToDisplay.push(linkedTasks[i]);
          this.autolinkerService.addLinkListeners(linkedTasks[i]["links"], this.elRef, this.router);
        }
        this.tasksToDisplay.sort((a, b) => {
          return a["order"] - b["order"];
        });
      });
      // });
    });
  }

  saveAssignTo(assignToUID: any): void {
    localStorage.setItem("taskAssignToU", assignToUID);
  }

  saveTask(task: string): void {
    localStorage.setItem("taskText", task);
  }

  constructor(
    private tasksService: TasksService,
    private datePipe: DatePipe,
    private elRef: ElementRef,
    private router: Router,
    private autolinkerService: AutolinkerService
  ) { }

  ngOnInit() {
    this.tasksService.userList.subscribe(u => {
      this.users = u;
      setTimeout(() => {
        let name = localStorage.getItem("taskAssignToU");
        let opt = this.opts.find(o => o.nativeElement.id === name);
        if(opt) {opt.nativeElement.selected = true;}
      }, 0);
    });
    this.getTasks();
  }

  ngAfterViewInit() {
    if(this.task) {this.task.nativeElement.value = localStorage.getItem("taskText");}
  }
}
