import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { catchError, tap, mergeMap } from 'rxjs/operators';
import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class InventoryService {
  postHeaders = {
    headers: new HttpHeaders({
      "Content-Type": "application/x-www-form-urlencoded"
    })
  };

  getInventoryApi: string = environment.apiRoot + "/inventory/get-inventory.php";
  updateInventoryApi: string = environment.apiRoot + "/inventory/update-inventory.php";
  addInventoryApi: string = environment.apiRoot + "/inventory/add-inventory.php";

  get schoolBuses(): Observable<Array<Object>> {
    return this.http.post<Array<Object>>(this.getInventoryApi, `type=school`, this.postHeaders).pipe(
      catchError(err => {
        console.log(err);
        return of([[]]);
      })
    );
  }

  get commercialBuses(): Observable<Array<Object>> {
    return this.http.post<Array<Object>>(this.getInventoryApi, `type=commercial`, this.postHeaders).pipe(
      catchError(err => {
        console.log(err);
        return of([[]]);
      })
    );
  }

  update(id, field, value): Observable<string> {
    return this.http.post<string>(this.updateInventoryApi, {id: id, field: field, value: value}, this.postHeaders).pipe(
      catchError(err => {
        console.log(err);
        return of("");
      })
    );
  }

  addBus(bus): Observable<any> {
    return this.http.post<any>(this.addInventoryApi, bus, this.postHeaders).pipe(
      catchError(err => {
        console.log(err);
        return of("");
      })
    );
  }

  constructor(
    private http: HttpClient
  ) { }
}
