import { Component, OnInit, Input, Output, EventEmitter, ViewChild } from '@angular/core';
import { VehicleService } from '../../services/vehicle.service';
import { AutoconfirmatorService } from '../../services/autoconfirmator.service';
import { pipe, of } from 'rxjs';
import { mergeMap } from 'rxjs/operators';
import { Vehicle } from '../../vehicle';
import { environment } from '../../../environments/environment';

@Component({
  selector: 'app-availability',
  templateUrl: './availability.component.html',
  styleUrls: ['./availability.component.scss']
})
export class AvailabilityComponent implements OnInit {
  @Input() vehicle: Vehicle;
  sendConfButtonText: string = "Send Confirmation E-Mail";
  confResult: string;
  @Output() toggle: EventEmitter<Object> = new EventEmitter<Object>();
  environment = environment;

  get canSendConfirmation(): boolean {
    return (
      !this.confResult &&
      this.vehicle &&
      this.vehicle["availability"]["do_not_confirm"] === "0" &&
      this.vehicle["owner"]["id"] !== "2282" &&
      this.vehicle["owner"]["email"] &&
      this.vehicle["owner"]["unsubscribe_confirmations"] === "0" &&
      this.vehicle["owner"]["no_auto_confirmations"] === "0" &&
      this.vehicle["owner"]["bounced_email"] === "0" &&
      this.vehicle["owner"]["invalid_email"] === "0"
    );
  }

  get autoConfDisabled(): boolean {
    return this.vehicle["owner"]["no_auto_confirmations"] === "1";
  }

  setAvailabilityConfirmed(): void {
    this.autoconfirmatorService.setAvailabilityConfirmed(this.vehicle.id)
      .subscribe(newDate => this.vehicle["availability"]["availability_last_confirmed"] = newDate);
  }

  switchNoConfirm(switchState: string): void {
    this.autoconfirmatorService.switchNoConfirm(this.vehicle.id, switchState).subscribe(() => {
      let confirmState = switchState === "on" ? "1" : "0";
      this.vehicle["availability"]["do_not_confirm"] = confirmState;
    });
  }

  toggleBrokerAgreement(state: boolean): void {
    this.vehicleService.editVehicle({
      vehid: this.vehicle.id,
      editType: "broker_agreement",
      editText: state
    }).pipe(
      mergeMap(() => {
        if(state) {
          return this.vehicleService.getDataField("broker_agreement", this.vehicle.id)
        }
        else {
          return of({});
        }
      })
    ).subscribe(bAgreement => {
      if(state) {
        this.vehicle["broker_agreement"]["on_file"] = bAgreement["on_file"];
        this.vehicle["broker_agreement"]["date"] = bAgreement["date"];
        this.vehicle["broker_agreement"]["username"] = bAgreement["username"];
      }
      else {
        this.vehicle["broker_agreement"]["on_file"] = "0";
      }
    });
  }

  sendConfirmation(): void {
    if(!window.confirm("Override and send confirmation e-mail for this bus?")) {return;}
    this.autoconfirmatorService.sendIndividualConfirmation(this.vehicle["id"]).subscribe(result => {
      if(result === "success") {
        this.confResult = "Confirmation E-Mail Sent Successfully";
        this.vehicle["availability"]["confirmation_last_sent"] = Date.now();
      }
      else {
        this.confResult = result;
      }
    });
  }

  constructor(
    private autoconfirmatorService: AutoconfirmatorService,
    private vehicleService: VehicleService
  ) { }

  ngOnInit() {
  }

}
