import { Component, OnInit, NgZone, ViewChild, ElementRef, HostListener } from '@angular/core';
import { ComponentCanDeactivate } from '../pending-changes.guard';
import { PublishingComponent } from './publishing/publishing.component';
import { Title } from '@angular/platform-browser';
import { TitleCasePipe } from '@angular/common';
import { Router, ActivatedRoute, NavigationStart, NavigationEnd } from '@angular/router';
import { CanComponentDeactivate } from '../save-changes.guard';
import { VehicleService } from '../services/vehicle.service';
import { ContactService } from '../services/contact.service';
import { UpdateLightsService } from '../services/update-lights.service';
import { Vehicle } from '../vehicle';
import { VehicleContactNotesComponent } from './vehicle-contact-notes/vehicle-contact-notes.component';
import { Subject, of, pipe, timer, EMPTY } from 'rxjs';
import { catchError, tap, mergeMap, debounceTime, debounce, distinctUntilChanged, groupBy } from 'rxjs/operators';
import { environment } from '../../environments/environment';

@Component({
  selector: 'app-vehicle-data',
  templateUrl: './vehicle-data.component.html',
  styleUrls: ['./vehicle-data.component.scss']
})
export class VehicleDataComponent implements OnInit, ComponentCanDeactivate {
  @HostListener('window:beforeunload', ['$event'])
  canDeactivate(event): boolean {
    return event && this.updateLightsService.nextChangesComing() ? false : true;
  }

  log(whtvr: string): void {
    console.log(whtvr);
  }

  vehicle: Vehicle;
  vehid: string;
  mobile: boolean;
  invalidVehicle: boolean = false;
  categories: Array<string>;
  private edits: Subject<Object> = new Subject<Object>();
  publishingDataToVerify: Object = {};
  @ViewChild(PublishingComponent) publishing: PublishingComponent;
  @ViewChild(VehicleContactNotesComponent) contactNotes: VehicleContactNotesComponent;
  @ViewChild('vehTitleHeader') vehTitleHeader: ElementRef;
  environment = environment;

  // canDeactivate(): boolean {
  //   return !this.updateLightsService.nextChangesComing() && this.updateLightsService.pendingChanges === 0;
  // }

  editPipe = () => pipe(
    tap(_ => {
      this.updateLightsService.nextChanges[_["editType"]] = true;
    }),
    groupBy(_ => _["editType"]),
    mergeMap(_ => _.pipe(
      distinctUntilChanged(),
      debounce(_ => _["typeahead"] ? timer(1000) : EMPTY)
    )),
    tap(_ => {
      this.updateLightsService.nextChanges[_["editType"]] = false;
      this.updateLightsService.pendingChanges++;
    }),
    mergeMap(editItem => {
      if(editItem["vehid"]) {
        return this.vehicleService.editVehicle(editItem);
      }
      else if(editItem["custid"]) {
        return this.contactService.editContact(editItem);
      }
    })
  );
  editSubscriptionFunction = (editItem) => {
    this.updateLightsService.pendingChanges--;
    if(!this.route.snapshot.paramMap.get('vehid') &&
    this.updateLightsService.pendingChanges===0 &&
    !this.updateLightsService.nextChangesComing()
  ) {
      this.router.navigateByUrl("/vehicledata/"+this.vehid);
    }
    if(editItem["editType"] === "hide_draft") {
      this.router.navigateByUrl("/addvehicle");
    }
    if(editItem.updateAfterEdit) {
      this.updateVehicle(editItem.editType);
    }
    if(editItem["editType"] === "price") {
      let price = editItem["editText"].replace(/[^\d]/g, "");
      // this.vehicle["price"] = price;

      price = parseInt(price) || 0;
      let cprice = (parseInt(this.vehicle["cprice"].replace(/[^\d]/g, "")) || 0);

      this.vehicle['markup'] = (price - cprice).toString();
    }
    else if(editItem["editType"] === "cprice") {
      let cprice = editItem["editText"].replace(/[^\d]/g, "");
      // this.vehicle["cprice"] = cprice;

      let price = (parseInt(this.vehicle["price"].replace(/[^\d]/g, "")) || 0);
      cprice = parseInt(cprice) || 0;

      this.vehicle['markup'] = (price - cprice).toString();
      // Update price date for cprice changes
      this.vehicle["cprice_date"] = Date.now().toString();
    }
    // For title, year, make and model, which are required to publish. If we don't do
    // this, the system has no way of checking that they were entered.
    // We don't want to enter that data into the actual model, because doing so
    // will cause an update to the view which can annoyingly interfere with slow
    // typing.
    else if(
      editItem["editType"] === "year" ||
      editItem["editType"] === "make" ||
      editItem["editType"] === "model" ||
      editItem["editType"] === "title"
    ) {
      this.publishingDataToVerify[editItem["editType"]] = editItem["editText"];
      // this.vehicle[editItem["editType"]] = editItem["editText"];
    }
  }

  editItem(editType: string, editText: string | boolean, typeahead: boolean = false, updateAfterEdit: boolean = false): void {
    if(!this.vehid) {
      this.vehicleService.newVehId.subscribe(newid => {
        this.vehid = newid;
        this.editItem(editType, editText, typeahead, false);
      });
      return;
    }
    let editItem = {
      "vehid": this.vehid,
      "editType": editType,
      "editText": editText,
      "typeahead": typeahead,
      "updateAfterEdit": updateAfterEdit
    };
    this.edits.next(editItem);
  }

  toggle(event: Object): void {
    let type = event['type'];
    let state = event['state'];

    if(state === true) {state = 'Y';}
    else if(state === false) {state = 'N';}
    this.editItem(type, state, false, false);
  }

  publish(): void {
    if(!this.publishingDataToVerify["title"]) {
      window.alert("ERROR: You must add a title before publishing");
      return;
    }
    if(
      !this.publishingDataToVerify["year"] ||
      !this.publishingDataToVerify["make"] ||
      !this.publishingDataToVerify["model"]
    ) {
      window.alert("ERROR: You must fill out year, make and model before publishing");
    }

    else if(window.confirm("Are you sure you want to publish this new listing?")) {
      this.editItem("unapproved", "0", false, true);
    }
  }

  hideDraft(): void {
    if(window.confirm("WARNING: Are you sure you want to hide this vehicle from the drafts list? You will not be able to recover it.")) {
      this.editItem("hide_draft", "1");
    }
  }

  updateVehicle(editType: string): void {
    if(editType === "unapproved") {
      location.reload();
      return;
    }
    this.vehicleService.getDataField(editType, this.vehicle.id)
    .subscribe(newData => {
      if(editType === "ownerid") {
        editType = "owner";
      }
      this.vehicle[editType] = newData;
    });
  }

  getVehicle(): void {
    this.vehicleService.getVehicleById(this.vehid)
    .subscribe(veh => {
      if(!veh["id"]) {
        this.invalidVehicle = true;
        this.titleService.setTitle("Invalid " + this.environment.capitalize(this.environment.vehicleName));
      }
      else {
        this.vehicle = veh;
        if(Object.keys(this.vehicle["contact_notes"]).length && this.contactNotes) {this.contactNotes.refreshNotes();}
        this.titleService.setTitle(veh.id + ' - ' + veh.title);
        if(veh['unapproved'] === '1') {
          this.publishingDataToVerify['year'] = veh['year'];
          this.publishingDataToVerify['make'] = veh['make'];
          this.publishingDataToVerify['model'] = veh['model'];
          this.publishingDataToVerify['title'] = veh['title'];
        }
      }
    });
  }

  ownerTypeahead(event: Object): void {
    this.edits.next({
      "custid": event["ownerid"],
      "editType": event["type"],
      "editText": event["value"],
      "typeahead": true,
      "updateAfterEdit": false
    });
  }

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private vehicleService: VehicleService,
    private contactService: ContactService,
    private zone: NgZone,
    private updateLightsService: UpdateLightsService,
    private titleService: Title,
    private titleCasePipe: TitleCasePipe
  ) { }

  ngOnInit() {
    let mobileMq = matchMedia('screen and (max-width: 690px)');
    this.mobile = mobileMq.matches;
    mobileMq.addListener(m => {
      this.zone.run(() => this.mobile = m.matches);
    });

    this.edits.pipe(this.editPipe()).subscribe(this.editSubscriptionFunction);

    this.route.params.subscribe(params => {
      this.vehid = params["vehid"];
      if(this.publishing) {
        this.publishing.clearSSErrors();
      }
      if(this.vehid) {
        this.getVehicle();
      }
      else {
        this.titleService.setTitle("Add New " + this.titleCasePipe.transform(this.environment.vehicleName));
      }
    });

    this.router.events.subscribe(event => {
      if(event instanceof NavigationStart) {
        this.updateLightsService.nextChanges["notebox"]=false;
      }
      else if(event instanceof NavigationEnd && this.route.snapshot.paramMap.get('vehid') !== this.vehid) {
        this.getVehicle();
      }
    });

    // Prevent accidental dragging of images into the text inputs from depositing
    // data into them.
    let inputs = document.querySelectorAll("input, textarea, .wysiwyg-editor");
    for(let i = 0; i < inputs.length; i++) {
      inputs[i]['ondrop'] = () => false;
    }
  }
}
