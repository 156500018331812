import { Component, OnInit, ViewChild, Input, ElementRef } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { Title } from '@angular/platform-browser';
import { ContactService } from '../services/contact.service';
import { Contact } from '../contact';
import { DisplayContactsTableComponent } from '../display-contacts-table/display-contacts-table.component';
import { Observable, Subject, of } from 'rxjs';
import {
  tap,
  debounceTime,
  distinctUntilChanged,
  switchMap,
  filter
} from 'rxjs/operators';
@Component({
  selector: 'app-contact-search',
  templateUrl: './contact-search.component.html',
  styleUrls: ['./contact-search.component.scss']
})
export class ContactSearchComponent implements OnInit {

  constructor(
    private contactService: ContactService,
    private titleService: Title,
    private router: Router
  ) { }
  private searchTerms = new Subject<Object>();
  @Input() embedded: boolean = false;
  @ViewChild(DisplayContactsTableComponent) table: DisplayContactsTableComponent;
  @ViewChild('custSearch') searchInput: ElementRef;
  search: Object = {
    searchTerm: "",
    searchNotes: "yes",
    limit: 50
  };
  scrollState: Object = {};
  scrollContainer: any;
  appendNextResult: boolean = false;
  @Input() forceSearchAll: boolean = false;
  resultCount: number = 0;

  reset(): void {
    this.searchInput.nativeElement.value = '';
    this.search["searchTerm"] = "";
    this.scrollState["search"] = JSON.parse(JSON.stringify(this.search));
    localStorage.setItem("contactTableScrollState", JSON.stringify(this.scrollState));
  }

  get resultsExist(): boolean {
    return !!(this.table.contacts && this.table.contacts.length);
  }

  get searchInProgress(): boolean {
    return this.table.loading;
  }

  searchContacts(searchTerm: string) {
    if(!searchTerm && this.embedded) {
      this.table.contacts = [];
      this.search["searchTerm"] = "";
      this.table.loading = false;
      this.scrollState["search"] = JSON.parse(JSON.stringify(this.search));
      localStorage.setItem("contactTableScrollState", JSON.stringify(this.scrollState));
      return;
    }
    // Get hyphens out of phone numbers. JS benchmarking shows this to be faster
    // with the regex replace conditioned on the regex test rather than just
    // running the replace every time, so don't change this unless you know
    // better.
    if(/\+?((\d+)-(\d*))+/.test(searchTerm)) {
      searchTerm = searchTerm.replace(/-/g, '');
    }
    this.search["searchTerm"] = searchTerm;
    this.table.filters["offset"] = 0;
    this.search["filters"] = this.table.filters;
    if(this.forceSearchAll || localStorage.getItem("userid") === "5") {
      this.search["filters"]["stage"] = "all";
    }
    this.scrollState["search"] = JSON.parse(JSON.stringify(this.search));
    if(!this.embedded) {
      localStorage.setItem('contactTableScrollState', JSON.stringify(this.scrollState));
    }
    this.appendNextResult = false;
    this.searchTerms.next(this.search);
  }

  updateScrollPosition(that: any): Function {
    return () => {
      let scrollPosition = that.scrollContainer.scrollTop;

      let scrollState = {
        scrollPosition: scrollPosition,
        search: JSON.parse(JSON.stringify(that.search))
      };
      scrollState["search"]["limit"] = that.resultCount;
      scrollState["search"]["filters"]["offset"] = 0;

      localStorage.setItem('contactTableScrollState', JSON.stringify(scrollState));
    }
  }

  ngOnInit() {
    this.scrollState = JSON.parse(localStorage.getItem('contactTableScrollState'));
    this.scrollContainer = document.getElementById("table-scroll-container");
    if(!this.scrollState || !Object.keys(this.scrollState).length) {
      this.scrollState = {
        scrollPosition: 0,
        search: JSON.parse(JSON.stringify(this.search))
      };
      localStorage.setItem('contactTableScrollState', JSON.stringify(this.scrollState));
    }

    this.scrollContainer.addEventListener('scroll', this.updateScrollPosition(this));
      // let scrollPosition = this.scrollContainer.scrollTop;
      //
      // let scrollState = {
      //   scrollPosition: scrollPosition,
      //   search: JSON.parse(JSON.stringify(this.search))
      // };
      // scrollState["search"]["limit"] = this.resultCount;
      // scrollState["search"]["filters"]["offset"] = 0;
      //
      // localStorage.setItem('contactTableScrollState', JSON.stringify(scrollState));


    this.searchTerms.pipe(
      //We check distinctUntilChanged and debounceTime, then we
      //run a filter to make sure the phone is a number
      //only.  The order here is important:  If we do not
      //let distinctUntilChanged see the input first, then
      //when the user backspaces all their input, the last
      //digit they backspaced will be stuck in the pipe.
      //If they type that same digit again, distinctUntilChanged
      //will fail and the search will not execute.
      // distinctUntilChanged(),
      debounceTime(400),
      tap(_ => {
        // this.table.removeNoMatches(_);
        if(!this.appendNextResult) {this.table.contacts = [];}
        this.table.loading = true;
      }),
      switchMap(search => {
        if(!search["searchTerm"] && this.embedded) {
          this.table.contacts = [];
          this.table.loading = false;
          return of([]);
        }
        else {return this.contactService.searchContacts(search);}
      })
    )
    .subscribe(response => {
      if(!this.search["searchTerm"] && this.embedded) {
        this.table.contacts = [];
        this.table.loading = false;
        return;
      }
      if(!this.embedded) {
        if(response && response.length > 0) {
          let resultWord = response.length === 1 ? "Result" : "Results";
          this.resultCount = response.length + this.table.filters["offset"];
          this.titleService.setTitle(this.resultCount + " " + resultWord + " - Contact Search");
          if(response.length > 50) {
            this.table.filters["offset"] = response.length - 50;
          }
        }
        else if(this.search["filters"]["offset"] === 0) {
          this.titleService.setTitle("Contact Search");
        }
      }
      this.table.setContacts(response, this.appendNextResult);
      this.table.loading = false;

      if(Object.keys(this.scrollState).length) {
        setTimeout(() => {
          this.scrollContainer.removeEventListener('scroll', this.updateScrollPosition);
          this.scrollContainer.scroll(0, this.scrollState["scrollPosition"]);
          this.scrollContainer.addEventListener('scroll', this.updateScrollPosition);
          this.scrollState = {};
        },0);
      }
    });
    this.table.reload.subscribe(append => {
      this.search["filters"] = this.table.filters;
      if(!append) {
        this.scrollState["search"] = this.search;
        localStorage.setItem('contactTableScrollState', JSON.stringify(this.scrollState));
      }
      this.appendNextResult = append;

      this.searchTerms.next(this.search);
    });
    if(!this.embedded) {
      this.titleService.setTitle("Contact Search");
      // Initialize with a blank search to load a full contact list.
      this.search["searchTerm"] = "";
      this.search["filters"] = {
        "stage": localStorage.getItem("userid") === "5" ? "all" : "customers",
        "offset": 0
      };

      // if(this.scrollState["scrollPosition"] === 0) {
        // this.searchTerms.next(this.search);
      // }
      // else {
        this.appendNextResult = true;
        this.search["searchTerm"] = this.scrollState["search"]["searchTerm"];
        this.scrollState["search"]["filters"] = this.scrollState["search"]["filters"] ? this.scrollState["search"]["filters"] : this.search["filters"];
        this.table.filters = JSON.parse(JSON.stringify(this.scrollState["search"]["filters"]));
        this.searchTerms.next(this.scrollState["search"]);
      // }
    }
  }
}
