import { Component, OnInit, Input, Output, EventEmitter, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { VehicleService } from '../../services/vehicle.service';
import { Vehicle } from '../../vehicle';
import { environment } from '../../../environments/environment';

@Component({
  selector: 'app-publishing',
  templateUrl: './publishing.component.html',
  styleUrls: ['./publishing.component.scss']
})
export class PublishingComponent implements OnInit {
  environment = environment;
  ssError: boolean = false;
  ssVehicles: Array<string> = [];
  @Input() vehicle: Vehicle;
  @Output() typeahead: EventEmitter<Object> = new EventEmitter<Object>();
  @Output('toggle') toggleEmitter: EventEmitter<Object> = new EventEmitter<Object>();
  @Output() publish: EventEmitter<void> = new EventEmitter<void>();
  @Output() hideDraft: EventEmitter<void> = new EventEmitter<void>();
  @ViewChild('ssSwitch') ssSwitch;
  copySucceeded: boolean = false;
  copyFailed: boolean = false;

  priceTypeahead(type: string, price: string): void {
    price = price.replace(/[^\d]/g, '');
    this.typeahead.emit({'type':type,'value':price});
  }

  toggle(type: string, state: boolean): void {
    if(this.ssError) {this.ssError = false;}
    if(this.ssVehicles.length) {this.ssVehicles = [];}
    if(type === 'ss' && state === true) {
      this.vehicleService.ssSlotOpen.subscribe(response => {
        if(response["open"] === 'true') {
          this.toggleEmitter.emit({type: type, state: state});
        }
        else if(response["open"] === 'false') {
          this.ssError = true;
          this.ssVehicles = response["vehicles"];
          this.ssSwitch.switchOn = false;
        }
      });
    }
    else {
      this.toggleEmitter.emit({type: type, state: state});
    }
  }

  clearSSErrors(): void {
    this.ssError = false;
    this.ssVehicles = [];
  }

  getEbayHtml(): void {
    this.vehicleService.getEbayHtml(this.vehicle["id"]).subscribe(html => {
      let textarea = document.createElement("textarea");
      textarea.innerHTML = html;
      textarea.setAttribute('readonly', '');
      textarea.style.position = "absolute";
      textarea.style.left = "-9999px";
      document.body.appendChild(textarea);
      let selected = document.getSelection().rangeCount > 0 ? document.getSelection().getRangeAt(0) : false;

      textarea.select();

      this.copySucceeded = false;
      this.copyFailed = false;

      if(document.execCommand("copy")) {
        this.copySucceeded = true;
      }
      else {
        this.copyFailed = true;
      }

      document.body.removeChild(textarea);
      if(selected) {
        document.getSelection().removeAllRanges();
        document.getSelection().addRange(selected);
      }
    });
  }

  constructor(
    private vehicleService: VehicleService,
    private router: Router
  ) { }

  ngOnInit() {
  }

}
