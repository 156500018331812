import { Component, OnInit, Input, Output, EventEmitter, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { Subject, pipe, of } from 'rxjs';
import { tap, groupBy, mergeMap, distinctUntilChanged } from 'rxjs/operators';
import { Vehicle } from '../../vehicle';
import { UpdateLightsService } from '../../services/update-lights.service';
import { ContactService } from '../../services/contact.service';
import { ContactNotesComponent } from '../contact-notes/contact-notes.component';
import { environment } from '../../../environments/environment';

@Component({
  selector: 'app-vehicles-interested',
  templateUrl: './vehicles-interested.component.html',
  styleUrls: ['./vehicles-interested.component.scss']
})
export class VehiclesInterestedComponent implements OnInit {
  @Input() vehicles_interested: Array<Vehicle>;
  @Input() custid: string;
  @Output() addVehicle: EventEmitter<string> = new EventEmitter<string>();
  @Output() removeVehicle: EventEmitter<string> = new EventEmitter<string>();
  notesToShow: Array<Object> = [];
  showNotesForVehicleId: string;
  imgUrlForVehicleNotes: string;
  vehNotesSubject: Subject<any> = new Subject<any>();
  indexOfVehicleToUpdate: string;
  environment = environment;
  @ViewChild(ContactNotesComponent) notesComponent: ContactNotesComponent;

  openVehicle(event: any, id: string): void {
    if(event["altKey"]) {
      window.open("/vehicledata/"+id, "_blank");
    }
    else {
      this.router.navigateByUrl("/vehicledata/"+id);
    }
  }

  editPipe = () => pipe(
    tap(_ => {
      this.updateLightsService.nextChanges[_["editType"]] = true;
      this.updateLightsService.nextChanges[_["notebox"]] = false;
    }),
    groupBy(_ => _["editType"]),
    mergeMap(_ => _),
    tap(_ => {
      this.updateLightsService.nextChanges[_["editType"]] = false;
      this.updateLightsService.pendingChanges++;
    }),
    mergeMap(editItem => this.contactService.editContact(editItem))
  );
  editSubscriptionFunction = (editItem) => {
    this.contactService.getDataField("vehnotes", this.custid, editItem["vehid"]).subscribe(result => {
      this.vehicles_interested[editItem["indexOfVehicleToUpdate"]]["notes"] = result;
      this.notesToShow = result;
      this.notesComponent.refreshNotes();
      this.updateLightsService.pendingChanges--;
    });
  }

  addInterestedVehicle(intVehInput: Object): void {
    let value = intVehInput["value"].replace(/[^\d]/, "");
    intVehInput["value"] = "";
    this.addVehicle.emit(value);
  }

  removeInterestedVehicle(intVeh: string): void {
    this.removeVehicle.emit(intVeh);
  }

  showOrHide(i: string): string {
    let vehicle = this.vehicles_interested[i];
    return this.showNotesForVehicleId === vehicle['id'] ? "Hide" : "Show";
  }

  toggleNotes(i?: string): void {
    if(!this.showNotesForVehicleId) {
      let vehicle = this.vehicles_interested[i];
      this.notesToShow = vehicle['notes'];
      this.showNotesForVehicleId = vehicle['id'];
      this.indexOfVehicleToUpdate = i;
      this.imgUrlForVehicleNotes = vehicle.getImgUrl('1');
    }
    else {
      this.notesToShow = [];
      this.showNotesForVehicleId = "";
      this.imgUrlForVehicleNotes = "";
    }
  }

  noteBoxTextChanged(noteBoxText: string): void {
    if(noteBoxText !== "") {this.updateLightsService.nextChanges["notebox"]=true;}
    else {this.updateLightsService.nextChanges["notebox"]=false;}
  }

  newVehNote(text: string): void {
    let editItem = {
      "custid": this.custid,
      "editType": "newvehnote",
      "editText": this.showNotesForVehicleId+"&"+text,
      "typeahead": false,
      "vehid": this.showNotesForVehicleId,
      "indexOfVehicleToUpdate": this.indexOfVehicleToUpdate
    };
    this.vehNotesSubject.next(editItem);
  }

  editNote(text: string): void {
    let noteid = text["slice"](0, text["indexOf"]("&"));
    let editText = text["slice"](text["indexOf"]("&")+1);

    let editItem = {
      "custid": this.custid,
      "editType": "editnote",
      "editText": editText,
      noteid: noteid,
      "typeahead": false,
      "vehid": this.showNotesForVehicleId,
      "indexOfVehicleToUpdate": this.indexOfVehicleToUpdate
    };

    this.vehNotesSubject.next(editItem);
  }

  constructor(
    private updateLightsService: UpdateLightsService,
    private contactService: ContactService,
    private router: Router
  ) { }

  ngOnInit() {
    this.vehNotesSubject.pipe(this.editPipe()).subscribe(this.editSubscriptionFunction);
  }

}
