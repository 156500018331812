import { Component, OnInit, Input, ElementRef, OnDestroy, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { UserNotesService } from '../../services/user-notes.service';
import { VehicleService } from '../../services/vehicle.service';
import { ContactService } from '../../services/contact.service';
import { AutolinkerService } from '../../services/autolinker.service';
import { DynamicLinkComponent } from '../../dynamic-link/dynamic-link.component';
import { Observable } from 'rxjs';
import { environment } from '../../../environments/environment';

@Component({
  selector: 'app-scratchpad',
  templateUrl: './scratchpad.component.html',
  styleUrls: ['./scratchpad.component.scss']
})
export class ScratchpadComponent implements OnInit {
  notes: Array<Object> = [];
  notesToDisplay: Array<Object> = [];
  @Input() popup: boolean = false;
  notesSubscription: any;
  @ViewChild('usernotesInput') userNotesInput: ElementRef;
  environment = environment;

  addNote(event: any): void {
    if(event && event.shiftKey) {return;}
    event.preventDefault();
    let note = event.target["value"];

    event.target["value"] = "";
    localStorage.removeItem("userNotesInput");
    note = note.trim();
    if(!note) {return;}

    this.userNotes.addNote(note);
  }

  deleteNote(noteId: string): void {
    this.userNotes.deleteNote(noteId);
  }

  editNote(textElement: HTMLElement, noteIndex: number): void {
    let removeContentEditable = (event) => {
      event.target.contentEditable = false;
      event.target.removeEventListener("blur", removeContentEditable);

      let noteId = this.notes[noteIndex]["id"];
      this.userNotes.editNote(noteId, event.target.textContent);
    };

    let blurTextElement = (event) => {
      if(event.key === "Enter" && !event.shiftKey) {
        event.target.textContent = event.target.textContent.trim();
        event.target.blur();
        event.target.removeEventListener("keyup", blurTextElement);
        event.target.removeEventListener("paste", pasteWithoutFormatting);
      }
    };

    let pasteWithoutFormatting = (event) => {
      event.preventDefault();
      let text = (event.originalEvent || event).clipboardData.getData("text/plain");
      document.execCommand("insertText", false, text);
    };

    textElement.addEventListener("blur", removeContentEditable);
    textElement.addEventListener("keyup", blurTextElement);
    textElement.addEventListener("paste", pasteWithoutFormatting);
    textElement.contentEditable = "true";
    textElement.focus();
    textElement.textContent = this.notes[noteIndex]["text"];
  }

  saveInput(input: string): void {
    localStorage.setItem("userNotesInput", input);
  }

  constructor(
    private userNotes: UserNotesService,
    private vehicleService: VehicleService,
    private contactService: ContactService,
    private elRef: ElementRef,
    private router: Router,
    private autolinkerService: AutolinkerService
  ) { }
  ngOnInit() {
    this.userNotesInput.nativeElement.value = localStorage.getItem("userNotesInput");
    this.notesSubscription = this.userNotes.notes.subscribe(notes => {
      this.notes = JSON.parse(JSON.stringify(notes));
      this.notesToDisplay = [];

      let noteTexts = [];
      for(let i = 0; i < notes.length; i++) {
        noteTexts.push(notes[i]["text"]);
      }

      let results = this.autolinkerService.autolink(noteTexts, this.elRef.nativeElement['localName']);
      new Promise(async resolve => {
        let linkedNotes = [];
        for(let i = 0; i < results.length; i++) {
          let linkedNote = await results[i]();
          notes[i]["text"] = linkedNote["text"];
          notes[i]["links"] = linkedNote["links"];
          notes[i]["order"] = linkedNote["order"];
          linkedNotes.push(notes[i]);
        }
        resolve(linkedNotes);
      }).then(linkedNotes => {
        for(let i = 0; i < linkedNotes["length"]; i++) {
          this.notesToDisplay.push(linkedNotes[i]);
          this.autolinkerService.addLinkListeners(linkedNotes[i]["links"], this.elRef, this.router);
        }
        this.notesToDisplay.sort((a, b) => {
          return a["order"] - b["order"];
        });
      });
    });
    this.userNotes.getUserNotes();
  }
  ngOnDestroy() {
    // This prevents the subscription from running again and again when we
    // navigate back to the dashboard after navigating away.
    this.notesSubscription.unsubscribe();
  }
}
