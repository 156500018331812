import { Component, OnInit, ViewChild } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { DisplayContactsTableComponent } from '../display-contacts-table/display-contacts-table.component';
import { ContactService } from '../services/contact.service';
import { Contact } from '../contact';

@Component({
  selector: 'app-show-all-contacts',
  templateUrl: './show-all-contacts.component.html',
  styleUrls: ['./show-all-contacts.component.scss']
})
export class ShowAllContactsComponent implements OnInit {
  @ViewChild(DisplayContactsTableComponent) table: DisplayContactsTableComponent;

  constructor(
    private contactService: ContactService,
    private titleService: Title
  ) { }

  getContacts(append: boolean): void {

    this.contactService.getAllContacts(this.table.filters)
    .subscribe(contacts => {

      this.table.setContacts(contacts, append);
      // contacts.forEach(contact => {
      //   this.table.contacts.push(contact);
      // });

      // this.table.loading = false;
    });
  }

  ngOnInit() {
    this.titleService.setTitle("Contacts By Order Updated");
    this.table.loading = true;
    this.getContacts(false);

    this.table.reload.subscribe(append => {
      this.getContacts(append);
    });
  }
}
