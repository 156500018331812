import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { catchError, tap, mergeMap, switchMap } from 'rxjs/operators';
import { Contact } from '../contact';
import { Vehicle } from '../vehicle';
import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ContactService {
  postHeaders = {
    headers: new HttpHeaders({
      "Content-Type": "application/x-www-form-urlencoded"
    })
  };

  getContactByIdApi: string = environment.apiRoot + "/customers/getcustomerbyid.php";
  searchContactsApi: string = environment.apiRoot + "/customers/searchcustomers.php";
  searchContactsByColumnApi: string = environment.apiRoot + "/customers/searchcustomersbycolumn.php";
  getDataFieldApi: string = environment.apiRoot + "/customers/getcustomerdatafield.php";
  addContactApi: string = environment.apiRoot + "/customers/addcustomer.php";
  getAllContactsApi: string = environment.apiRoot + "/customers/getallcustomers.php";
  contactEditUrl: string = environment.apiRoot + "/customers/customer-edit.php";
  vehicleEditUrl: string = environment.apiRoot + "/vehicles/vehicle-edit.php";
  getClaimedNotUpdatedUrl: string = environment.apiRoot + "/customers/get-claimed-not-updated.php";
  checkContactIdUrl: string = environment.apiRoot + "/customers/contact-id-exists.php";

  checkContactId(id: string): Observable<any> {
    return this.http.get<any>(this.checkContactIdUrl + "?id="+id).pipe(
      catchError(err => {
        console.log(err);
        return of("");
      })
    );
  }

  getContactById(id: string): Observable<Contact> {
    return this.http.get<Contact>(this.getContactByIdApi + `?id=${id}`).pipe(
      mergeMap(contact => {
        contact['adminUrl'] = environment.adminRoot + "/contactdata/" + contact.id;
        if(contact['vehicles_interested']) {
          for(let i = 0; i < contact['vehicles_interested'].length; i++) {
            let veh = contact['vehicles_interested'][i];
            veh['adminUrl'] = "/vehicledata/" + veh['id'];
            // veh['date_created'] = new Date(Date.parse(veh['date_created'].replace(/-/g,'/')))
            contact['vehicles_interested'][i] = new Vehicle(veh);
          }
        }
        if(contact['vehicles_owned']) {
          for(let i = 0; i < contact['vehicles_owned'].length; i++) {
            let veh = contact['vehicles_owned'][i];
            veh['adminUrl'] = "/vehicledata/" + veh['id'];
            contact['vehicles_owned'][i] = new Vehicle(veh);
          }
        }
        return of(new Contact(contact));
      }),
      catchError(err => {
        console.log(err);
        return of(new Contact());
      })
    );
  }

  searchContacts(search: Object): Observable<Contact[]> {
    return this.http.get<Contact[]>(this.searchContactsApi+"?search="+JSON.stringify(search), this.postHeaders).pipe(
      tap(contacts => {
        if(contacts && contacts.length) {
          for(let i = 0; i < contacts.length; i++) {
            contacts[i] = new Contact(contacts[i]);
            // contacts[i]['date_updated'] = new Date(Date.parse(contacts[i]['date_updated'].replace(/-/g,'/')));
          }
        }
      }),
      catchError(err => {
        console.log(err);
        return of([]);
      })
    );
  }

  searchContactsByColumn(term: string, column: string): Observable<Contact[]> {
    return this.http.get<Contact[]>(this.searchContactsByColumnApi + `?searchterm=${term}&column=${column}`).pipe(
      catchError(err => {
        console.log(err);
        return of([]);
      })
    );
  }

  getDataField(dataField: string, custid: string, vehid?: string): Observable<any> {
    let queryStr = `?datafield=${dataField}&custid=${custid}`;
    if(vehid) {
      queryStr += `&vehid=${vehid}`;
    }
    return this.http.get<string>(this.getDataFieldApi + queryStr).pipe(
      mergeMap(data => {
        if(dataField === "vehicles_interested" || dataField === "remvehicles_interested") {
          let vehs = [];
          for(let i = 0; i < data.length; i++) {
            data[i]['adminUrl'] = "/vehicledata/" + data[i]['id'];
            vehs.push(new Vehicle(data[i]));
          }
          return of(vehs);
        }
        else {return of(data);}
      }),
      catchError(err => {
        console.log(err);
        return of("");
      })
    );
  }

  addContact(name: string = "", phone: string = "", email: string = "", stageZero: boolean = false): Observable<string> {
    let obj = {
      name: name,
      phone: phone,
      email: email,
      stageZero: stageZero
    };
    return this.http.post<string>(this.addContactApi, obj, this.postHeaders).pipe(
      catchError(err => {
        console.log(err);
        return of("");
      })
    );
  }

  getAllContacts(filters: Object): Observable<Contact[]> {
    return this.http.post<Contact[]>(this.getAllContactsApi, filters, this.postHeaders).pipe(
      switchMap(contacts => {
        if(contacts && contacts.length) {
          for(let i = 0; i < contacts.length; i++) {
            contacts[i] = new Contact(contacts[i]);
          }
        }
        return of(contacts);
      }),
      catchError(err => {
        console.log(err);
        return of([]);
      })
    );
  }

  editContact(editItem: Object): Observable<any> {
    return this.http.post<string>(this.contactEditUrl, JSON.stringify(editItem), this.postHeaders)
    .pipe(
      catchError(err => {
        console.log(err);
        return of("");
      }),
      mergeMap(_ => of(editItem))
    )
  }

  generateNewContactId(properties?: Object, stageZero: boolean = false): Observable<string> {
    let json = {
      "editType": "newcust",
      "stageZero": stageZero ? "yes" : "no"
    };
    if(properties) {
      json['properties'] = properties;
    }

    return this.http.post<string>(this.contactEditUrl, JSON.stringify(json), this.postHeaders).pipe(
      catchError(error => {
        console.log(error);
        return of("");
      })
    );
  }

  addNewOwner(properties?: Object): Observable<string> {
    let json = {
      "editType": "newcust",
      "stageZero": true
    };
    if(properties) {
      json['properties'] = properties;
    }

    return this.http.post<string>(this.contactEditUrl, JSON.stringify(json), this.postHeaders).pipe(
      catchError(error => {
        console.log(error);
        return of("");
      })
    );
  }

  get claimedNotUpdated(): Observable<Array<Contact>> {
    return this.http.get<Array<Contact>>(this.getClaimedNotUpdatedUrl).pipe(
      tap(contacts => {
        if(contacts && contacts.length) {
          for(let i = 0; i < contacts.length; i++) {
            contacts[i] = new Contact(contacts[i]);
          }
        }
      }),
      catchError(error => {
        console.log(error);
        return of([new Contact()]);
      })
    );
  }

  constructor(private http: HttpClient) { }
}
