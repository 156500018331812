import { Component, OnInit, Input, ComponentFactoryResolver, ViewChild } from '@angular/core';
import { PopupComponentDirective } from '../popup-component.directive';

@Component({
  selector: 'app-popup-panel',
  templateUrl: './popup-panel.component.html',
  styleUrls: ['./popup-panel.component.scss']
})
export class PopupPanelComponent implements OnInit {
  @Input() title: string;
  panelOpen: boolean = false;
  @ViewChild(PopupComponentDirective) popupComponent: PopupComponentDirective;

  constructor(private componentFactoryResolver: ComponentFactoryResolver) { }

  ngOnInit() {
  }

}
