// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

// Don't forget get-vehicle-images.php!

const environment_office = {
  production: false,
  imgRoot: "https://old.busesforsale.com/Buses/%VEH_DIR%/%VEH_DIR%",
  apiRoot: "https://old.busesforsale.com/admin/api",
  adminRoot: "http://10.1.10.133:4200",
  vehicleName: "bus",
  vehicleNamePlural: "buses",
  capitalize: function(word) {
    let firstLetter = word.charAt(0);
    firstLetter = firstLetter.toUpperCase();
    return firstLetter + word.slice(1);
  },
  sendEmail: function(email: string): void {
    if(!email) {return;}

    let el = document.createElement("a");
    el.href = "mailto:"+email;
    el.id = "sendemailelement";
    document.body.appendChild(el);
    document.getElementById("sendemailelement").click();
    document.body.removeChild(el);
  },

  callPhone: function(number: string): void {
    if(!number) {return;}

    let el = document.createElement("a");
    el.href = "tel:"+number;
    el.id = "phonecallelement";
    document.body.appendChild(el);
    document.getElementById("phonecallelement").click();
    document.body.removeChild(el);
  },

  openUrl: function(url: string): void {
    if(!url) {return;}

    let el = document.createElement("a");
    let regex = /^http(s)?:\/\//;

    if(!regex.test(url)) {
      url = "http://" + url;
    }

    el.href = url;
    el.target = "_blank";
    el.rel = "noopener";
    el.id = "openlinkelement";
    document.body.appendChild(el);
    document.getElementById("openlinkelement").click();
    document.body.removeChild(el);
  },

  openRoute: function(url: string, event?: any, router?: any): void {
    // Somehow, this allows the app to get the real current selection instead of
    // grabbing the selection from before the click event.
    setTimeout(() => undefined, 0);
    if(event) {
      event.preventDefault();
      event.stopPropagation();
    }
    if(event && document.getSelection().type === "Range") {
      if(
        document.getSelection().anchorNode.parentElement === event.target ||
        document.getSelection().focusNode.parentElement === event.target
      ) {
        return;
      }
    }
    if((event && event["altKey"]) || !event) {
      window.open(url, "_blank");
    }
    else {
      router.navigateByUrl(url);
    }
  }
};

const environment_remote = {
  production: false,
  imgRoot: "http://50.195.103.166:8080/Buses/%VEH_DIR%/%VEH_DIR%",
  apiRoot: "http://50.195.103.166:8080/api",
  adminRoot: "http://50.195.103.166:4200",
  vehicleName: "bus",
  vehicleNamePlural: "buses",
  capitalize: function(word) {
    let firstLetter = word.charAt(0);
    firstLetter = firstLetter.toUpperCase();
    return firstLetter + word.slice(1);
  },
  sendEmail: function(email: string): void {
    if(!email) {return;}

    let el = document.createElement("a");
    el.href = "mailto:"+email;
    el.id = "sendemailelement";
    document.body.appendChild(el);
    document.getElementById("sendemailelement").click();
    document.body.removeChild(el);
  },
  callPhone: function(number: string): void {
    if(!number) {return;}

    let el = document.createElement("a");
    el.href = "tel:"+number;
    el.id = "phonecallelement";
    document.body.appendChild(el);
    document.getElementById("phonecallelement").click();
    document.body.removeChild(el);
  },
  openUrl: function(url: string): void {
    if(!url) {return;}

    let el = document.createElement("a");
    let regex = /^http(s)?:\/\//;

    if(!regex.test(url)) {
      url = "http://" + url;
    }

    el.href = url;
    el.target = "_blank";
    el.rel = "noopener";
    el.id = "openlinkelement";
    document.body.appendChild(el);
    document.getElementById("openlinkelement").click();
    document.body.removeChild(el);
  }
};

export const environment = environment_office;

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
