import { Component, OnInit, ViewChild, ElementRef, HostListener } from '@angular/core';
import { ComponentCanDeactivate } from '../pending-changes.guard';
import { SuppliersService } from '../services/suppliers.service';
import { UpdateLightsService } from '../services/update-lights.service';
import { Observable, Subject, of, pipe, EMPTY, timer } from 'rxjs';
import { tap, mergeMap, debounce, distinctUntilChanged, groupBy } from 'rxjs/operators';
import { environment } from '../../environments/environment';

@Component({
  selector: 'app-suppliers',
  templateUrl: './suppliers.component.html',
  styleUrls: ['./suppliers.component.scss']
})
export class SuppliersComponent implements OnInit, ComponentCanDeactivate {
  @HostListener('window:beforeunload', ['$event'])
  canDeactivate(event): boolean {
    return event && this.updateLightsService.nextChangesComing() ? false : true;
  }
  suppliers: Array<Object>;
  suppliersToShow: Array<Object>;
  showOnly: Object = {
    "School Buses": false,
    "Commercial Buses": false
  }
  newSupplier: Object = {
    types: []
  };
  edits: Subject<Object> = new Subject<Object>();
  @ViewChild('schoolCheck') schoolCheck: ElementRef;
  @ViewChild('commercialCheck') commercialCheck: ElementRef;
  environment = environment;

  editsPipe = () => pipe(
    tap(_ => this.updateLightsService.nextChanges[_["field"]] = true),
    groupBy(_ => _["field"]),
    mergeMap(_ => _.pipe(
      distinctUntilChanged(),
      debounce(_ => _["nopause"] ? EMPTY : timer(1000))
    )),
    tap(_ => {
      this.updateLightsService.nextChanges[_["field"]] = false;
      this.updateLightsService.pendingChanges++;
    }),
    mergeMap(_ => this.suppliersService.updateSupplier(_["id"], _["field"], _["value"]))
  );

  subscriptionFunction = (result) => {
    this.updateLightsService.pendingChanges--;
  }

  edit(id: string, field: string, value: string, nopause: boolean = false): void {
    this.edits.next({
      id: id,
      field: field,
      value: value,
      nopause: nopause
    });
  }

  get vehTypesString(): string {
    let str = "";
    if(this.schoolCheck.nativeElement.checked) {
      str += "School";
    }
    if(this.commercialCheck.nativeElement.checked) {
      str += ";Commercial";
    }
    return str;
  }

  addSupplier(): void {
    let supplier = JSON.parse(JSON.stringify(this.newSupplier));

    if(supplier["phone"]) {
      supplier["phone"] = supplier["phone"].replace(/[^\d]/g, '');
    }

    if(supplier["types"]) {
      supplier["types"] = supplier["types"].join(";");
    }

    let newSupplierInputs = document.querySelectorAll('.new-supplier-row td[contenteditable="true"], .new-supplier-row select, .new-supplier-row input');
    for(let i = 0; i < newSupplierInputs.length; i++) {
      let input = newSupplierInputs[i];
      if(input["tagName"] === "TD") {
        input["textContent"] = "";
      }
      else if(input["type"] && input["type"] === "checkbox"){
        input["checked"] = false;
      }
      else {
        input["value"] = "";
      }
    }
    this.suppliersService.addSupplier(supplier).subscribe(() => this.refreshSuppliers());
  }

  openUrl(url: string, event: any): void {

    let el = document.createElement("a");
    let regex = /^http(s)?:\/\//;

    if(!regex.test(url)) {
      url = "http://" + url;
    }

    el.href = url;
    el.target = "_blank";
    el.rel = "noopener";
    el.id = "openlinkelement";
    document.body.appendChild(el);
    document.getElementById("openlinkelement").click();
    document.body.removeChild(el);
  }

  updateShowOnly(): void {
    let showAll = true;
    for(let item in this.showOnly) {
      if(item !== 'state' && this.showOnly[item]) {
        showAll = false;
      }
      else if(item === 'state' && this.showOnly['state'] !== 'Any') {
        showAll = false;
      }
    }

    if(!showAll) {
      for(let item in this.showOnly) {
        this.suppliersToShow = [];
        for(let i = 0; i < this.suppliers.length; i++) {
          let supplier = this.suppliers[i];
          if(item !== 'state') {
            for(let k = 0; k < supplier["types"].length; k++) {
              if(this.showOnly[supplier["types"][k]]) {
                this.suppliersToShow.push(supplier);
              }
            }
          }
          else {
            if(this.showOnly['state'] === supplier["state"]) {
              this.suppliersToShow.push(supplier);
            }
          }
        }
      }
    }

    else {
      this.suppliersToShow = this.suppliers;
    }
  }

  clearShowOnly(): void {
    let checkBoxes = document.querySelectorAll("input[type='checkbox']");
    document.querySelector("#state-select > option[value='Any']")["selected"] = true;

    for(let i = 0; i < checkBoxes.length; i++) {
      checkBoxes[i]["checked"] = false;
    }

    for(let item in this.showOnly) {
      this.showOnly[item] = item === 'state' ? 'Any' : false;
    }

    this.updateShowOnly();
  }

  refreshSuppliers(): void {
    this.suppliersService.suppliers.subscribe(suppliers => {
      this.suppliers = JSON.parse(JSON.stringify(suppliers));
      this.suppliersToShow = JSON.parse(JSON.stringify(suppliers));
    });
  }

  constructor(
    private suppliersService: SuppliersService,
    private updateLightsService: UpdateLightsService
  ) { }

  ngOnInit() {
    this.refreshSuppliers();
    this.edits.pipe(this.editsPipe()).subscribe(this.subscriptionFunction);
  }

}
