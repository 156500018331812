import { CanDeactivate } from '@angular/router';
import { Injectable } from '@angular/core';

export interface ComponentCanDeactivate {
  canDeactivate: (event?: any) => boolean;
}

@Injectable()
export class PendingChangesGuard implements CanDeactivate<ComponentCanDeactivate> {
  canDeactivate(component: ComponentCanDeactivate): boolean {
    // if there are no pending changes, just allow deactivation; else confirm first
    return component.canDeactivate() ?
      true :
      confirm();
  }
}
