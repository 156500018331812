import { Pipe, PipeTransform } from '@angular/core';
import { DatePipe } from '@angular/common';

@Pipe({
  name: 'datePipeExtended'
})
export class DatePipeExtendedPipe extends DatePipe implements PipeTransform {
  // The only reason for this is if we don't convert the dates coming from the
  // mySQL database to JavaScript Date object, Safari can't handle it. That's it.

  transform(value: any, args?: any): any {
    if(!value || typeof value === "number") {return;}
    if(!(value instanceof Date)) {
      value = new Date(Date.parse(value.replace(/-/g,'/')));
    }
    return super.transform(value,args);
  }

}
