import { Component, OnInit, Input, Output, EventEmitter, ViewChild } from '@angular/core';
import { Vehicle } from '../../vehicle';

@Component({
  selector: 'app-basic-info',
  templateUrl: './basic-info.component.html',
  styleUrls: ['./basic-info.component.scss']
})
export class BasicInfoComponent implements OnInit {
  @ViewChild('luggage_overhead') luggage_overhead;
  @ViewChild('luggage_underfloor') luggage_underfloor;
  @ViewChild('luggage_rear') luggage_rear;
  @Input() vehicle: Vehicle;
  @Output() typeahead: EventEmitter<Object> = new EventEmitter<Object>();
  @Output() changeItem: EventEmitter<Object> = new EventEmitter<Object>();

  changeLuggage(): void {
    let luggageStr = "";
    if(this.luggage_overhead.nativeElement.checked) {
      luggageStr += this.luggage_overhead.nativeElement.value + ",";
    }
    if(this.luggage_underfloor.nativeElement.checked) {
      luggageStr += this.luggage_underfloor.nativeElement.value + ",";
    }
    if(this.luggage_rear.nativeElement.checked) {
      luggageStr += this.luggage_rear.nativeElement.value;
    }

    this.changeItem.emit({type: 'luggage', value: luggageStr});
  }

  changeWheelchair(wheelchair: string): void {
    this.changeItem.emit({type: 'wheelchair', value: wheelchair});
  }

  constructor() { }

  ngOnInit() {
  }
}
