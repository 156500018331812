import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-claimed-by',
  templateUrl: './claimed-by.component.html',
  styleUrls: ['./claimed-by.component.scss']
})
export class ClaimedByComponent implements OnInit {
  @Input() claimantName: string;
  @Input() claimantId: string;
  @Output() claimant: EventEmitter<string> = new EventEmitter<string>();

  claimButtonText(): string {
    if(!this.claimantId) {
      return "Claim";
    }
    else if(this.claimantId && this.claimantId === localStorage.getItem("userid")) {
      return "Unclaim";
    }

    else {
      return "";
    }
  }

  emit(): void {
    if(!this.claimantId) {
      this.claimant.emit(localStorage.getItem("userid"));
    }
    else {
      this.claimant.emit("NULL");
    }
  }

  constructor() { }

  ngOnInit() {

  }

}
