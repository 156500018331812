import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { Router } from '@angular/router';
import { environment } from '../../../environments/environment';

@Component({
  selector: 'app-inv-table',
  templateUrl: './inv-table.component.html',
  styleUrls: ['./inv-table.component.scss']
})
export class InvTableComponent implements OnInit {
  @Input() buses: Array<Object>;
  @Input() category: string;
  @Input() newFolderNumber: number;
  @Input() addingInProgress: boolean = false;
  @Input() grandTotalMoneySpent: number = 0;
  @Input() grandTotalPurchasePrice: number = 0;
  @Input() grandTotalEstimatedSalePrice: number = 0;
  @Output() edit: EventEmitter<Object> = new EventEmitter<Object>();
  @Output() addNewBus: EventEmitter<Object> = new EventEmitter<Object>();
  newBus: Object = {};
  missing: Object = {};
  environment = environment;

  duplicate(): void {
    let busToDuplicate = this.buses.reduce((prev, current) => (parseInt(prev["id"]) > parseInt(current["id"])) ? prev : current);
    this.newBus["stock_number"] = busToDuplicate["stock_number"];
    this.newBus["year"] = busToDuplicate["year"];
    this.newBus["make"] = busToDuplicate["make"];
    this.newBus["body"] = busToDuplicate["body"];
    this.newBus["passengers"] = busToDuplicate["passengers"];
    this.newBus["purchase_price"] = busToDuplicate["purchase_price"];
  }

  goToStockNum(event: any, id: string): void {
    if(event["altKey"]) {
      window.open("/vehicledata/"+id, "_blank");
    }
    else {
      this.router.navigateByUrl("/vehicledata/"+id);
    }
  }

  emitEdit(id, field, value, formatAsNumber = false, nopause = false): void {
    if(field === "sold") {
      if(!window.confirm("Confirm mark sold?")) {
        return;
      }
      else if(field === "vin" || field === "purchase_price" || field === "money_spent" || field === "estimated_sale_price" || field === "retail_price") {
        // We need to locally replace the VIN because if you enter a duplicate
        // VIN for a new bus and then change the VIN of the bus already in the
        // list rather than that of the new bus you're entering, you will still
        // get an error and not be allowed to submit the new bus unless you
        // refresh the page.

        // We have to do the prices to make sure the grand total calculation works
        // without having to refresh the page.
        let busIndex = this.buses.findIndex(bus => bus["id"] === id);
        this.buses[busIndex][field] = value;
      }
      else {
        let busIndex = this.buses.findIndex(bus => bus["id"] === id);
        this.buses.splice(busIndex, 1);
      }
    }
    if(formatAsNumber) {
      value = value.replace(/[^\d.]/, '');
    }
    let event = {
      id: id,
      field: field,
      value: value,
      nopause: nopause
    };
    this.edit.emit(event);
  }

  addBus(): void {
    if(this.addingInProgress) {return;}
    this.addingInProgress = true;
    let newBusVin = this.newBus['vin'] || "";
    newBusVin = newBusVin.trim();
    if(newBusVin && newBusVin.length !== 17) {
      let confMsg = "WARNING: VIN is";
      if(newBusVin.length < 17) {confMsg += " only";}
      confMsg += " " + newBusVin.length;
      let digitWord = newBusVin.length === 1 ? "digit" : "digits";
      confMsg += " " + digitWord + " long. Proceed anyway?";
      if(!window.confirm(confMsg)) {
        this.addingInProgress = false;
        return;
      }
    }
    let matchingBus = false;
    if(newBusVin) {
      let match = this.buses.find(bus => bus['vin'] === newBusVin);
      if(match && Object.keys(match).length) {matchingBus = true;}
    }

    if(matchingBus) {
      window.alert("ERROR: You already entered that VIN");
      this.addingInProgress = false;
      return;
    }

    let errCols = [];
    let error = false;
    // for(let key in this.newBus) {
    //   // let header = document.getElementById("header-"+key);
    //   if(!this.newBus[key]) {
    //     // header.style.color = "red";
    //     this.missing[key] = true;
    //     errCols.push(key);
    //     if(!error) {error = true;}
    //   }
    //   else {
    //     // header.style.color = "black";
    //     this.missing[key] = false;
    //   }
    // }

    if(error) {
      setTimeout(() => window.alert("ERROR: Please fill in the following columns: "+errCols.join(", ")), 0);
      this.addingInProgress = false;
      return;
    }

    let newBusInputs = document.querySelectorAll(".new-bus-row > td[contenteditable='true']");
    for(let i = 0; i < newBusInputs.length; i++) {
      let child = newBusInputs[i];
      child.textContent = '';
    }
    this.newBus['category'] = this.category;

    this.addNewBus.emit(this.newBus);
    this.newBus = {};
    this.addingInProgress = false;
  }

  total(parameter: string): string {
    if(!this.buses) {return "";}
    let price = 0;
    for(let i = 0; i < this.buses.length; i++) {
      let pprice = this.buses[i][parameter].replace(',', '');
      price += parseFloat(pprice);
    }
    return price.toString();
  }

  sortNewestToOldest(newToOldSwitch: boolean) {
    if(newToOldSwitch) {
      this.buses.sort((a, b) => {
        return b["id"] - a["id"];
      });
    }
    else {
      this.normalSort();
    }
  }

  sortByBusNum(busNumSwitch: boolean) {
    if(busNumSwitch) {
      this.buses.sort((a, b) => {
        // Show the ones with no bus number first.
        let busNumA = a["bus_num"] ? a["bus_num"].toUpperCase() : 1;
        let busNumB = b["bus_num"] ? b["bus_num"].toUpperCase() : 1;
        return new Intl.Collator('en', {numeric: true}).compare(busNumA, busNumB);
      });
    }
    else {
      this.normalSort();
    }
  }

  normalSort(): void {
    this.buses.sort((a, b) => {
      if(a["year"] !== b["year"]) {
        return a["year"] - b["year"];
      }

      else if(a["make"] !== b["make"]) {
        return a["make"].localeCompare(b["make"]);
      }

      else if(a["model"] !== b["model"]) {
        return a["model"].localeCompare(b["model"]);
      }

      else if(a["mileage"] !== b["mileage"]) {
        let mileageA = parseInt(a["mileage"].replace(/[^\d]/g, ""));
        let mileageB = parseInt(b["mileage"].replace(/[^\d]/g, ""));

        return mileageA - mileageB;
      }

      else {
        return b["id"] - a["id"];
      }
    });
  }

  constructor(
    private router: Router
  ) { }

  ngOnInit() {

  }

}
