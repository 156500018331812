import { Component, OnInit, Input, ElementRef, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { Reminder } from '../reminder';
import { ReminderService } from '../services/reminder.service';
import { AutolinkerService } from '../services/autolinker.service';
import { environment } from '../../environments/environment';

@Component({
  selector: 'app-reminder-panel',
  templateUrl: './reminder-panel.component.html',
  styleUrls: ['./reminder-panel.component.scss']
})
export class ReminderPanelComponent implements OnInit {
  @Input() reminders: Reminder[] = [];
  remindersToDisplay: Reminder[] = [];
  @Input() custid: string = "";
  @Input() custLinks: boolean = false;
  reminderSaved: boolean = false;
  remindToggles: Object = {
    done: false,
    future: false
  };
  environment = environment;
  @ViewChild('reminderdate') reminderDate: ElementRef;
  @ViewChild('remindertext') reminderText: ElementRef;

  openContact(event: any, id: string): void {
    if(event["altKey"]) {
      window.open("/contactdata/"+id, "_blank");
    }
    else {
      this.router.navigateByUrl("/contactdata/"+id);
    }
  }

  addReminder(dateField: any, textField: any): void {
    let date = dateField.value;
    let text = textField.value;
    if(!date || !text) {return;}
    dateField.value = this.today;
    textField.value = "";
    localStorage.removeItem("reminderdate");
    localStorage.removeItem("remindertext");
    this.reminderService.addReminder(date,text,this.custid).subscribe(() => {
      this.reminderSaved = true;
      this.getReminders();
      setTimeout(() => {
        this.reminderSaved = false;
      }, 5000);
    });
  }

  markReminderDone(reminderId: string): void {
    this.reminderService.markReminderDone(reminderId).subscribe(() => {
      this.getReminders();
    });
  }

  getReminders(): void {
    if(this.custid === "NEW") {return;}
    this.reminderService.getReminders(this.remindToggles['done'], this.remindToggles['future'], this.custid)
    .subscribe(reminders => {
      this.reminders = reminders;
      let reminderTexts = [];
      for(let i = 0; i < reminders.length; i++) {
        reminderTexts.push(reminders[i]["remind_text"]);
      }
      let results = this.autolinkerService.autolink(reminderTexts, this.elRef.nativeElement['localName']);

      new Promise(async resolve => {
        let linkedReminders = [];
        for(let i = 0; i < results.length; i++) {
          let linkedReminder = await results[i]();
          reminders[i]["remind_text"] = linkedReminder["text"];
          reminders[i]["order"] = linkedReminder["order"];
          reminders[i]["links"] = linkedReminder["links"];
          linkedReminders.push(reminders[i]);
        }
        resolve(linkedReminders);
      }).then(linkedReminders => {
        this.remindersToDisplay = [];
        for(let i = 0; i < linkedReminders["length"]; i++) {
          this.remindersToDisplay.push(linkedReminders[i]);
          this.autolinkerService.addLinkListeners(linkedReminders[i]["links"], this.elRef, this.router);
        }
        this.remindersToDisplay.sort((a, b) => {
          return a["order"] - b["order"];
        });
      });
    });
  }

  get today(): string {
    return new Date().toLocaleDateString('en-CA');
  }

  saveDate(dateInput: any): void {
    if(!dateInput.value) {dateInput.value = this.today;}
    else {localStorage.setItem("reminderdate", dateInput.value);}
  }

  saveReminder(reminder: string): void {
    localStorage.setItem("remindertext", reminder);
  }

  constructor(
    private reminderService: ReminderService,
    private autolinkerService: AutolinkerService,
    private elRef: ElementRef,
    private router: Router
  ) { }

  ngOnInit() {
    let reminderdate = localStorage.getItem("reminderdate") || this.today;
    let remindertext = localStorage.getItem("remindertext");
    this.reminderDate.nativeElement.value = reminderdate;
    this.reminderText.nativeElement.value = remindertext;

    if(this.custid !== "NEW") {
      this.getReminders();
    }
  }
}
