import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, of, pipe } from 'rxjs';
import { catchError, tap, mergeMap } from 'rxjs/operators';
import { Vehicle } from '../vehicle';
import { Contact } from '../contact';
import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class VehicleService {
  vehicleSearchApi: string = environment.apiRoot + "/vehicles/searchvehicles.php";
  getVehicleByIdApi: string = environment.apiRoot + "/vehicles/getvehiclebyid.php";
  vehicleEditUrl: string = environment.apiRoot + "/vehicles/vehicle-edit.php";
  getDataFieldApi: string = environment.apiRoot + "/vehicles/getvehicledatafield.php";
  uploadImagesUrl: string = environment.apiRoot + "/vehicles/upload-vehicle-images.php";
  getImageThumbnailsUrl: string = environment.apiRoot + "/vehicles/get-vehicle-thumbnails.php";
  slideShowSlotCheckUrl: string = environment.apiRoot + "/vehicles/slideshow-slot-check.php";
  createNewVehicleUrl: string = environment.apiRoot + "/vehicles/create-new-vehicle.php";
  getUserSubmissionsUrl: string = environment.apiRoot + "/vehicles/get-user-submissions.php";
  getSubmissionUrl: string = environment.apiRoot + "/vehicles/get-submission.php";
  getSubmissionPicsUrl: string = environment.apiRoot + "/vehicles/get-submission-pics.php";
  downloadPicsAsZipUrl: string = environment.apiRoot + "/vehicles/zip-download-images.php";
  deleteSubmissionPicsUrl: string = environment.apiRoot + "/vehicles/deluserimages.php";
  checkMatchingOwnersUrl: string = environment.apiRoot + "/vehicles/check-matching-owners.php";
  createNewVehicleFromSubmissionUrl: string = environment.apiRoot + "/vehicles/create-from-submission.php";
  getEbayHtmlUrl: string = environment.apiRoot + "/vehicles/ebay-html-generator/get-ebay-html.php";
  getVehicleCategoriesUrl: string = environment.apiRoot + "/vehicles/get-vehicle-categories.php";
  getDraftsUrl: string = environment.apiRoot + "/vehicles/get-drafts.php";
  checkVehicleIdUrl: string = environment.apiRoot + "/vehicles/vehicle-id-exists.php";

  postHeaders = {
    headers: new HttpHeaders({
      "Content-Type": "application/x-www-form-urlencoded"
    })
  };

  checkVehicleId(id: string): Observable<any> {
    return this.http.get<any>(this.checkVehicleIdUrl + "?id="+id).pipe(
      catchError(err => {
        console.log(err);
        return of("");
      })
    );
  }

  searchVehicles(term: string, offset: number = 0): Observable<Vehicle[]> {
    return this.http.get<Vehicle[]>(this.vehicleSearchApi + `?term=${term}&offset=${offset}`).pipe(
      tap(vehicles => {
        if(vehicles) {
          for(let i = 0; i < vehicles.length; i++) {
            vehicles[i] = new Vehicle(vehicles[i]);
          }
        }
      }),
      catchError(error => {
        console.log(error);
        return of([new Vehicle()]);
      })
    );
  }

  getDrafts(): Observable<Vehicle[]> {
    return this.http.get<Vehicle[]>(this.getDraftsUrl).pipe(
      tap(vehicles => {
        if(vehicles) {
          for(let i = 0; i < vehicles.length; i++) {
            vehicles[i] = new Vehicle(vehicles[i]);
          }
        }
      }),
      catchError(error => {
        console.log(error);
        return of([new Vehicle()]);
      })
    );
  }

  getVehicleById(id: string): Observable<Vehicle> {
    return this.http.get<Vehicle>(this.getVehicleByIdApi + `?id=${id}`).pipe(
      tap(vehicle => {
        vehicle['adminUrl'] = "/vehicledata/" + vehicle['id'];
        for(let i = 0; i < vehicle['contacts_interested'].length; i++) {
          vehicle['contacts_interested'][i] = new Contact(vehicle['contacts_interested'][i]);
        }
      }),
      mergeMap(vehicle => of(new Vehicle(vehicle))),
      catchError(error => {
        console.log(error);
        return of(new Vehicle());
      })
    )
  }

  userSubmissions(offset: number): Observable<Array<Object>> {
    return this.http.get<Array<Object>>(this.getUserSubmissionsUrl+`?offset=${offset}`).pipe(
      tap(vehicles => {
        if(vehicles) {
          for(let i = 0; i < vehicles.length; i++) {
            vehicles[i] = new Vehicle(vehicles[i]);
          }
        }
      }),
      catchError(error => {
        console.log(error);
        return of([{}]);
      })
    );
  }

  getSubmission(id: string): Observable<Vehicle> {
    return this.http.get<Object>(this.getSubmissionUrl+`?id=${id}`).pipe(
      mergeMap(veh => of(new Vehicle(veh))),
      catchError(error => {
        console.log(error);
        return of(new Vehicle());
      })
    );
  }

  getSubmissionPics(id: string): Observable<Array<string>> {
    return this.http.get<Array<string>>(this.getSubmissionPicsUrl+`?id=${id}`).pipe(
      catchError(error => {
        console.log(error);
        return of([""]);
      })
    );
  }

  deleteUserSubmissionImages(id: string): Observable<void> {
    return this.http.post<any>(this.deleteSubmissionPicsUrl, `id=${id}`, this.postHeaders).pipe(
      catchError(error => {
        console.log(error);
        return of();
      })
    );
  }

  downloadVehiclePicsAsZip(id: string, userbus: boolean): Observable<any> {
    return this.http.get(this.downloadPicsAsZipUrl+`?id=${id}&userbus=${userbus}`, {responseType: "arraybuffer"}).pipe(
      catchError(error => {
        console.log(error);
        return of("");
      })
    );
  }

  getDataField(dataField: string, vehid: string): Observable<any> {
    return this.http.get<string>(this.getDataFieldApi + `?datafield=${dataField}&vehid=${vehid}`).pipe(
      catchError(err => {
        console.log(err);
        return of("");
      })
    );
  }

  editVehicle(editItem: Object): Observable<any> {
    return this.http.post<string>(this.vehicleEditUrl, JSON.stringify(editItem), this.postHeaders)
    .pipe(
      catchError(err => {
        console.log(err);
        return of("");
      }),
      mergeMap(_ => of({
        typeahead: editItem["typeahead"],
        editType: editItem["editType"],
        editText: editItem["editText"],
        updateAfterEdit: editItem["updateAfterEdit"]
      }))
    );
  }

  checkMatchingOwners(owner: Object): Observable<Array<Object>> {
    return this.http.post<Array<Object>>(this.checkMatchingOwnersUrl, JSON.stringify(owner), this.postHeaders).pipe(
      catchError(error => {
        console.log(error);
        return of([{}]);
      })
    );
  }

  createNewVehicle(title: string): Observable<string> {
    return this.http.post<string>(this.createNewVehicleUrl, `title=${title}`, this.postHeaders).pipe(
      catchError(error => {
        console.log(error);
        return of("");
      })
    );
  }

  createNewVehicleFromSubmission(properties: Object): Observable<string> {
    return this.http.post<string>(this.createNewVehicleFromSubmissionUrl, JSON.stringify(properties), this.postHeaders).pipe(
      catchError(error => {
        console.log(error);
        return of("");
      })
    );
  }

  get newVehId(): Observable<string> {
    return this.http.get<string>(this.createNewVehicleUrl).pipe(
      catchError(error => {
        console.log(error);
        return of("");
      })
    );
  }

  get ssSlotOpen(): Observable<Object> {
    return this.http.get<Object>(this.slideShowSlotCheckUrl).pipe(
      catchError(error => {
        console.log(error);
        return of(false);
      })
    );
  }

  uploadImage(image: Object): Observable<void> {
    return this.http.post<any>(this.uploadImagesUrl, JSON.stringify(image), this.postHeaders).pipe(
      catchError(error => {
        console.log(error);
        return of(false);
      })
    );
  }

  getImageThumbnails(id: string): Observable<Array<string>> {
    return this.http.get<Array<string>>(this.getImageThumbnailsUrl + `?id=${id}`).pipe(
      catchError(error => {
        console.log(error);
        return of([])
      })
    );
  }

  updateImageOrder(id: string, dir: string, orderChange: Object): Observable<void> {
    let json = {
      "editType": "updateImgOrder",
      "vehid": id,
      "orderChange": orderChange
    };
    return this.http.post<any>(this.vehicleEditUrl, JSON.stringify(json), this.postHeaders).pipe(
      catchError(error => {
        console.log(error);
        return of("");
      })
    );
  }

  updateImageDescriptions(id: string, descriptions: Array<string>): Observable<void> {
    let json = {
      "editType": "alt",
      "vehid": id,
      "editText": descriptions
    };
    return this.http.post<any>(this.vehicleEditUrl, JSON.stringify(json), this.postHeaders).pipe(
      catchError(error => {
        console.log(error);
        return of("");
      })
    );
  }

  deleteImage(id: string, imageNumber: string): Observable<void> {
    let json = {
      "editType": "deleteImg",
      "vehid": id,
      "imageNumber": imageNumber
    };
    return this.http.post<any>(this.vehicleEditUrl, JSON.stringify(json), this.postHeaders).pipe(
      catchError(error => {
        console.log(error);
        return of("");
      })
    );
  }

  getEbayHtml(vehid: string): Observable<string> {
    return this.http.get<string>(this.getEbayHtmlUrl+`?vehid=${vehid}`).pipe(
      catchError(error => {
        console.log(error);
        return of("");
      })
    );
  }

  get vehicleCategories(): Observable<Array<string>> {
    return this.http.get<Array<string>>(this.getVehicleCategoriesUrl).pipe(
      catchError(error => {
        console.log(error);
        return of([""])
      })
    );
  }

  constructor(private http: HttpClient) { }
}
