import { Component, OnInit, Input } from '@angular/core';
import { Router } from '@angular/router';
import { environment } from '../../../environments/environment';

@Component({
  selector: 'app-contact-form-submissions',
  templateUrl: './contact-form-submissions.component.html',
  styleUrls: ['./contact-form-submissions.component.scss']
})
export class ContactFormSubmissionsComponent implements OnInit {
  @Input() messages: Array<Object>;
  environment = environment;

  gotoVeh(id: string, event: any): void {
    if(!id) {return;}
    this.environment.openRoute("/vehicledata/"+id, event, this.router);
  }

  parseMsg(msg: string): string {
    let parser = new DOMParser;
    let dom = parser.parseFromString(
      '<!DOCTYPE html><body>'+msg, "text/html"
    );
    return dom.body.textContent;
  }

  constructor(
    private router: Router
  ) { }

  ngOnInit() {
  }

}
