import { Component, OnInit, ViewChild } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { ContactService } from '../services/contact.service';
import { ContactSearchComponent } from '../contact-search/contact-search.component';

@Component({
  selector: 'app-add-contact',
  templateUrl: './add-contact.component.html',
  styleUrls: ['./add-contact.component.scss']
})
export class AddContactComponent implements OnInit {
  name: string = "";
  phone: string = "";
  email: string = "";
  @ViewChild(ContactSearchComponent) search: ContactSearchComponent;
  submitButtonClicked: boolean = false;

  set(field: string, value: string): void {
    switch(field) {
      case 'name':
        this.name = value;
        break;
      case 'phone':
        this.phone = value;
        break;
      case 'email':
        this.email = value;
        break;
      default:
        return;
    }
    if(field === "phone") {
      value = value.replace(/[^\d+]/g, '');
    }
    this.search.searchContacts(value);
  }

  resultsExist(): boolean {
    return this.search.resultsExist;
  }

  submit(): void {
    if(this.submitButtonClicked || this.search.searchInProgress) {return;}
    this.submitButtonClicked = true;
    this.phone = this.phone.replace(/[^\d+]/g, '');
    this.contactService.addContact(this.name,this.phone,this.email)
    .subscribe(id => {
      this.router.navigateByUrl("/contactdata/"+id);
    });
  }

  constructor(
    private contactService: ContactService,
    private router: Router,
    private titleService: Title
  ) { }

  ngOnInit() {
    this.titleService.setTitle("Add New Contact");
  }

}
