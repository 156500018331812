import { Component, OnInit} from '@angular/core';
import { Router } from '@angular/router';
import * as $ from 'jquery';
import { LoginService } from '../services/login.service';
import { environment } from '../../environments/environment';

@Component({
  selector: 'app-side-nav',
  templateUrl: './side-nav.component.html',
  styleUrls: ['./side-nav.component.scss']
})
export class SideNavComponent implements OnInit {
  environment = environment;

  constructor(
    private router: Router,
    private loginService: LoginService
  ) { }

  logout(): void {
    this.loginService.logout();
  }

  getRouter(): Router {
    return this.router;
  }

  ngOnInit() {
    $('.side-nav-button').mouseenter(function(e) {
      var type = e.target.dataset.menuitem;
      $('#side-nav-submenu-' + type).css({
        display: "inline-block"
      });
    });

    $('.side-nav-button').mouseleave(function(e) {
      var type = e.target.dataset.menuitem;
      $('#side-nav-submenu-' + type).css({
        display: "none"
      });
    });

    $('.side-nav-submenu').on("mouseleave click", function() {
      $(this).css({
        display: "none"
      });
    });
  }

}
