import { Component, OnInit, Input } from '@angular/core';
import { MobileNavService } from '../services/mobile-nav.service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {
  get navOpen(): boolean {
    return this.mobileNavService["navOpen"];
  }
  setNavOpen(open: boolean): void {
    this.mobileNavService["navOpen"] = open;
  }
  constructor(private mobileNavService: MobileNavService) { }

  ngOnInit() {
  }

}
