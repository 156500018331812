import { Pipe, PipeTransform } from '@angular/core';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';

@Pipe({
  name: 'bypassHtml'
})
export class BypassHtmlPipe implements PipeTransform {

  transform(value: any, args?: any): any {
    return value ? this.sanitizer.bypassSecurityTrustHtml(value) : "";
  }
  constructor(private sanitizer: DomSanitizer) {}
}
