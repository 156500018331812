import { Component, OnInit, Input } from '@angular/core';
import { Vehicle } from '../../vehicle';

@Component({
  selector: 'app-accommodations',
  templateUrl: './accommodations.component.html',
  styleUrls: ['./accommodations.component.scss','../vehicle-data.component.scss']
})
export class AccommodationsComponent implements OnInit {
  @Input() vehicle: Vehicle;

  constructor() { }

  ngOnInit() {
  }

}
