import { Component, OnInit, HostListener } from '@angular/core';
import { ComponentCanDeactivate } from '../pending-changes.guard';
import { Title } from '@angular/platform-browser';
import { ReminderService } from '../services/reminder.service';
import { ContactService } from '../services/contact.service';
import { Reminder } from '../reminder';
import { Contact } from '../contact';
import { UpdateLightsService } from '../services/update-lights.service';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss']
})
export class DashboardComponent implements OnInit, ComponentCanDeactivate {
  @HostListener('window:beforeunload', ['$event'])
  canDeactivate(event): boolean {
    return event && this.updateLightsService.nextChangesComing() ? false : true;
  }

  date: Date = new Date();
  name: string = localStorage.getItem("name");
  reminders: Reminder[] = [];
  claimedNotUpdated: Array<Contact>;

  constructor(
    private reminderService: ReminderService,
    private contactService: ContactService,
    private titleService: Title,
    private updateLightsService: UpdateLightsService,
  ) { }

  ngOnInit() {
    this.titleService.setTitle("BFS Management System");
    this.reminderService.getReminders()
    .subscribe(reminders => this.reminders = reminders);
  }
}
