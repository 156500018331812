import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'phone'
})
export class PhonePipe implements PipeTransform {

  transform(number: string, args?: any): string {
    if(!number) { return ""; /* Handle blank field */}
    number = number.replace(/[^+\d]/g, "");
    number = number.trim();
    let nums = number.split("");
    nums.splice(6, 0, '-');
    nums.splice(3, 0, '-');
    return nums.join("");
  }

}
