import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { Router } from '@angular/router';
import { Contact } from '../contact';
import { Subject } from 'rxjs';
import { environment } from '../../environments/environment';

@Component({
  selector: 'app-display-contacts-table',
  templateUrl: './display-contacts-table.component.html',
  styleUrls: ['./display-contacts-table.component.scss']
})
export class DisplayContactsTableComponent implements OnInit {
  @Input() embedded: boolean = false;
  showFilters: boolean = false;
  stages: NodeListOf<HTMLElement>;
  showAll: boolean = true;
  showStage: string = "all";
  @Output() reset: EventEmitter<void> = new EventEmitter<void>();

  showOnlyBool: Object = {
    "prior_purchaser": false
  };
  showOnlySPS: Object = {};
  showOnlyClaimed: boolean = false;

  filters: Object = {
    "stage": "customers",
    "offset": 0,
    "showOnlyClaimed": false,
    "prior_purchaser": false,
    "sps": {}
  };

  @Input() contacts: Contact[] = [];
  contactsToShow: Contact[] = [];

  @Input() loading: boolean = false;
  @Output() reload: Subject<any> = new Subject<any>();
  @Input() lastCustId: string = "0";
  environment = environment;

  setContacts(contacts: Array<Contact>, append: boolean = false): void {
    if(!append) {this.contacts = contacts;}
    else if(contacts) {
      if(this.contacts.length && contacts[0]["id"] === this.contacts[0]["id"]) {return;}
      this.contacts = this.contacts.concat(contacts);
    }
    this.loading = false;
  }

  removeNoMatches(searchTerm: string) {
    if(this.contacts && this.contacts.length) {
      //Create a new regex from the latest search term,
      //test each current contact
      //with it and remove the contacts that do not
      //match the latest search.
      this.contacts = this.contacts.filter(cust => {
        for(let custAttr in cust) {
          if(new RegExp(searchTerm, 'g').test(cust[custAttr])) {return false;}
        }
        return true;
      });
    }
  }

  onScroll(): void {
    this.filters["offset"] += 50;
    this.reload.next(true);
  }

  updateShowOnly(): void {
    this.contacts = [];
    this.filters["offset"] = 0;
    this.loading = true;
    if(this.showStage === 'all') {this.showAll = true;}
    this.stages = document.getElementsByName("show-only-stage");

    // Prevent TypeScript from screeching about forEach not existing on type
    // NodeListOf.
    Array.from(this.stages).forEach(radioButton => {
      if(radioButton["checked"]) {
        this.filters["stage"] = radioButton["value"];
        // this.showStage = radioButton["value"];
      }
    });

    // "N" indicates that filter should be removed.
    for(let key in this.filters["sps"]) {
      if(this.filters["sps"][key] === "N") {
        delete this.filters["sps"][key];
      }
    }

    this.reload.next(false);

  }

  clearShowOnly(): void {
    this.showAll = true;
    document.getElementsByName("show-only-stage")[0]["checked"] = true;

    this.filters = {
      "stage": "all",
      "offset": 0,
      "showOnlyClaimed": false,
      "prior_purchaser": false,
      "sps": {}
    };

    let spsRadios = document.querySelectorAll(".supplier-purchaser-table-container input[type='radio']");

    Array.from(spsRadios).forEach(radio => {
      if(radio["value"] === "N") {radio["checked"] = true;}
    });

    let addlOptions = document.getElementsByClassName("additional-options-checkbox");
    Array.from(addlOptions).forEach(checkbox => {
      checkbox["checked"] = false;
    });
    this.reset.emit();
    this.updateShowOnly();
  }

  getRouter(): Router {
    return this.router;
  }

  constructor(
    private router: Router
  ) { }

  ngOnInit() {
  }

}
