import { Component, OnInit, Input } from '@angular/core';
import { Contact } from '../../contact';

@Component({
  selector: 'app-transaction-stage',
  templateUrl: './transaction-stage.component.html',
  styleUrls: ['./transaction-stage.component.scss']
})
export class TransactionStageComponent implements OnInit {
  @Input() contact: Contact;

  constructor() { }

  ngOnInit() {
  }

}
