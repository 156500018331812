import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ContactService } from '../../services/contact.service';
import { Contact } from '../../contact';
import { environment } from '../../../environments/environment';

@Component({
  selector: 'app-claimed-not-updated',
  templateUrl: './claimed-not-updated.component.html',
  styleUrls: ['./claimed-not-updated.component.scss']
})
export class ClaimedNotUpdatedComponent implements OnInit {
  claimedNotUpdated: Array<Contact>;
  environment = environment;

  openContact(event: any, id: string): void {
    if(event["altKey"]) {
      window.open("/contactdata/"+id, "_blank");
    }
    else {
      this.router.navigateByUrl("/contactdata/"+id);
    }
  }

  constructor(
    private contactService: ContactService,
    private router: Router
  ) { }

  ngOnInit() {
    this.contactService.claimedNotUpdated.subscribe(cnu => this.claimedNotUpdated = cnu);
  }
}
