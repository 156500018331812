import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { Router } from '@angular/router';
import { Vehicle } from '../../vehicle';
import { environment } from '../../../environments/environment';

@Component({
  selector: 'app-vehicles-owned',
  templateUrl: './vehicles-owned.component.html',
  styleUrls: ['./vehicles-owned.component.scss']
})
export class VehiclesOwnedComponent implements OnInit {
  @Input() vehicles: Array<Vehicle>;
  @Input() noAutoConf: boolean = false;
  @Output() toggleAutoConf: EventEmitter<boolean> = new EventEmitter<boolean>();
  environment = environment;

  toggleAC(event: boolean): void {
    this.toggleAutoConf.emit(event);
  }

  constructor(
    private router: Router
  ) { }

  ngOnInit() {
  }

}
