import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { of } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { Vehicle } from '../../vehicle';
import { environment } from '../../../environments/environment';

@Component({
  selector: 'app-categories',
  templateUrl: './categories.component.html',
  styleUrls: ['./categories.component.scss']
})
export class CategoriesComponent implements OnInit {
  @Input() vehicle: Vehicle;
  categories: Array<string>;
  @Output() changeCat: EventEmitter<Object> = new EventEmitter<Object>();

  emitChangeCat(cat: string, value: string): void {
    this.changeCat.emit({type: cat, value: value});
  }

  constructor(private http: HttpClient) { }

  ngOnInit() {
    this.http.get<Array<string>>(environment.apiRoot + "/get-categories.php").pipe(
      catchError(error => {
        console.log(error);
        return of([]);
      })
    ).subscribe(categories => {
      this.categories = categories;
    });
  }

}
