import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class UpdateLightsService {
  pendingChanges: number = 0;
  nextChanges: Object = {};

  nextChangesComing(): boolean {
    for(let change in this.nextChanges) {
      if(this.nextChanges[change] === true) {
        return true;
      }
    }
    return false;
  }

  constructor() { }
}
