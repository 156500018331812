import { Component, OnInit, HostListener } from '@angular/core';
import { ComponentCanDeactivate } from '../pending-changes.guard';
import { Title } from '@angular/platform-browser';
import { Router, ActivatedRoute } from '@angular/router';
import { VehicleService } from '../services/vehicle.service';
import { ContactService } from '../services/contact.service';
import { UpdateLightsService } from '../services/update-lights.service';
import { Vehicle } from '../vehicle';
import { Subject, of, pipe, timer, EMPTY } from 'rxjs';
import { catchError, tap, mergeMap, debounceTime, debounce, distinctUntilChanged, groupBy } from 'rxjs/operators';
import { environment } from '../../environments/environment';

@Component({
  selector: 'app-vehicle-submissions',
  templateUrl: './vehicle-submissions.component.html',
  styleUrls: ['./vehicle-submissions.component.scss']
})
export class VehicleSubmissionsComponent implements OnInit, ComponentCanDeactivate {
  @HostListener('window:beforeunload', ['$event'])
  canDeactivate(event): boolean {
    return event && this.updateLightsService.nextChangesComing() ? false : true;
  }
  // This will display either a list of user vehicle submisisons, or the details
  // of an individual submission depending upon whether a vehicle ID is present
  // in the URL.
  vehicles: Array<Object>;
  vehid: string;
  vehicle: Vehicle;
  images: Array<string>;
  checkOwnerDialogue: boolean = false;
  ownersToCheck: Array<Object>;
  zipButtonText: string = "Download Images As ZIP";
  zipButtonClicked: boolean = false;
  private edits: Subject<Object> = new Subject<Object>();
  environment = environment;

  editPipe = () => pipe(
    tap(_ => {
      this.updateLightsService.nextChanges[_["editType"]] = true;
    }),
    groupBy(_ => _["editType"]),
    mergeMap(_ => _.pipe(
      distinctUntilChanged(),
      debounce(_ => _["typeahead"] ? timer(1000) : EMPTY)
    )),
    tap(_ => {
      this.updateLightsService.nextChanges[_["editType"]] = false;
      this.updateLightsService.pendingChanges++;
    }),
    mergeMap(editItem => this.vehicleService.editVehicle(editItem))
  );
  editSubscriptionFunction = (editItem) => {
    this.updateLightsService.pendingChanges--;
    if(editItem["editType"] === "review_post") {
      this.vehicle["review_post"] = editItem["editText"];
    }
    // if(editItem["updateAfterEdit"]) {
    //   this.vehicleService.getDataField(editItem["editType"], this.vehicle.id)
    //   .subscribe(newData => this.vehicle[editItem["editType"]] = newData);
    // }
  }

  edit(editType: any, editText: any, typeahead: boolean): void {
    let updateAfterEdit = editType === "review_post" ? false : true;

    let editItem = {
      "vehid": this.vehid,
      "editType": editType,
      "editText": editText,
      "typeahead": typeahead,
      "updateAfterEdit": updateAfterEdit
    };
    this.edits.next(editItem);
  }

  openVehicle(event: any, id: string): void {
    if(event["altKey"]) {
      window.open("/submittedvehicle/"+id, "_blank");
    }
    else {
      this.router.navigateByUrl("/submittedvehicle/"+id);
    }
  }

  get isAuthorizedPriceReviewer(): boolean {
    let userid = localStorage.getItem("userid");
    return userid === "5";
  }

  zipDownloadImages(): void {
    if(this.zipButtonClicked) {return;}
    this.zipButtonClicked = true;
    this.zipButtonText = "Downloading...";
    this.vehicleService.downloadVehiclePicsAsZip(this.vehid, true).subscribe(zip => {
      let blob = new Blob([zip], {type: "application/zip"});
      let url = window.URL.createObjectURL(blob);
      let a = document.createElement("a");
      a.href = url;
      a.download = this.vehicle["owner"]["owner_name"]+" "+this.vehicle["year"]+" "+this.vehicle["make"];
      a.download = a.download.replace(/\./g, "");
      a.download = a.download.trim();
      a.style.display = "none";
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
      window.URL.revokeObjectURL(url);
      this.zipButtonText = "Download Images As ZIP";
      this.zipButtonClicked = false;
    });
  }

  // We need this function so as to be able to stop the click event from
  // propagating to the row containing the owner's info and thereby creating
  // draft listings on every owner we open to view.
  openOwner(id: string, event: any): void {
    event.stopPropagation();
    this.environment.openRoute('/contactdata/'+id);
  }

  genNewListing(ownerid: string = ""): void {
    if(this.isAuthorizedPriceReviewer) {
      alert("Tom, you don't have to click that. The price you typed in saves automatically.");
      return;
    }
    if(!ownerid) {
      this.vehicleService.checkMatchingOwners(this.vehicle["owner"]).subscribe(owners => {
        if(owners.length > 0) {
          this.ownersToCheck = owners;
          this.checkOwnerDialogue = true;
        }
        else {
          this.genNewListing("new");
        }
      });
    }
    else if(ownerid === "new") {
      let name = this.vehicle["owner"]["owner_name"];
      let phone = this.vehicle["owner"]["phone"];
      let email = this.vehicle["owner"]["email"];

      this.contactService.addContact(name, phone, email, true).subscribe(newid => {
        this.genNewListing(newid);
      });
    }
    else {
      this.checkOwnerDialogue = false;
      let newVehicleProps = {};
      let propKeys = [
        "year",
        "make",
        "model",
        "body",
        "cprice",
        "city",
        "state",
        "zip",
        "passengers",
        "wheelchair",
        "luggage",
        "engine",
        "transmission",
        "enginemile",
        "transmile",
        "mileage",
        "chassis",
        "country",
        "vin",
        "ownerid"
      ];
      // Gotta filter commas out of these, potentially
      let numericalPropertiesToFilter = [
        "cprice",
        "mileage",
        "enginemile",
        "transmile"
      ];
      for(let key of propKeys) {
        if(key === "luggage") {
          let luggageStr = "";
          if(this.vehicle["luggage"].includes("Over Head")) {
            luggageStr += "Over Head,";
          }
          if(this.vehicle["luggage"].includes("Under Floor")) {
            luggageStr += "Under Floor,";
          }
          if(this.vehicle["luggage"].includes("Rear")) {
            luggageStr += "Rear";
          }
          newVehicleProps["luggage"] = luggageStr;
        }
        else {
          newVehicleProps[key] = this.vehicle[key];
        }
        if(numericalPropertiesToFilter.includes(key) && newVehicleProps[key]) {
          newVehicleProps[key] = newVehicleProps[key].replace(/[^\d]/g, "");
        }
      }


      newVehicleProps["ownerid"] = ownerid;
      this.vehicleService.createNewVehicleFromSubmission(newVehicleProps).subscribe(newid => {
        window.open("/vehicledata/"+newid, "_blank");
      });
    }
  }

  deleteImages(): void {
    if(!window.confirm("WARNING! This will delete this submission's pictures from the server. Are you sure?")) {return;}

    this.vehicleService.deleteUserSubmissionImages(this.vehid).subscribe(() => this.images = []);
  }

  onScroll(): void {
    console.log('scroll detektit lol')
  }

  constructor(
    private vehicleService: VehicleService,
    private contactService: ContactService,
    private router: Router,
    private route: ActivatedRoute,
    private titleService: Title,
    private updateLightsService: UpdateLightsService
  ) { }

  ngOnInit() {
    this.vehid = this.route.snapshot.paramMap.get("vehid");
    this.edits.pipe(this.editPipe()).subscribe(this.editSubscriptionFunction);

    if(!this.vehid) {
      this.titleService.setTitle("View "+this.environment.vehicleName.charAt(0).toUpperCase()+this.environment.vehicleName.slice(1)+" Submissions");
      this.vehicleService.userSubmissions(0).subscribe(submissions => this.vehicles = submissions);
    }
    else {
      let veh = this.vehicleService.getSubmission(this.vehid).subscribe(veh => {
        this.vehicle = veh;
        this.titleService.setTitle(veh["owner"]["owner_name"]+": "+veh["year"]+" "+veh["make"]+" "+veh["model"]);
        this.vehicleService.getSubmissionPics(this.vehid).subscribe(picUrls => this.images = picUrls);
      });
    }
  }

}
