import { Vehicle } from './vehicle';

export class Contact {
  id: string;
  name: string;
  cname: string;
  email: string;
  phone: string;
  fax: string;
  cphone: string;
  city: string;
  state: string;
  notes: Array<Object>;
  vehicles_interested: Array<Vehicle>;
  vehicles_owned: Array<Vehicle>;
  date_updated: string;
  updated_by: string;
  claimant: Object = {
    claimed_by: <string> null,
    claimant_id: <string> null
  };
  sps: Object = {};
  title: string;
  our_vehicle: string;
  _transaction_stage: string;
  adminUrl: string;
  days_since_last_note: number;
  contact_form_submissions: Array<Object>;
  needs_vehicle_found: string;
  prior_purchaser: string;
  unsubscribe_confirmations: string;
  bounced_email: string;
  invalid_email: string;


  get transaction_stage(): string {
    let stage = {
      "0": "0 - Inactive/Owner Only",
      "1": "1 - Initial Inquiry",
      "2": "2 - Continuous Communication",
      "3a": "3a - Conference Call",
      "3b": "3b - Appointment Pending",
      "4": "4 - Appointment",
      "5a": "5a - Negotiations In Progress",
      "5b": "5b - Awaiting Decision",
      "6": "6 - Transaction In Progress"
    };
    return stage[this._transaction_stage];
  }

  set transaction_stage(ts: string) {
    this._transaction_stage = ts;
  }

  get supplier_purchaser_status(): string {
    let codes = [];
    for(let key in this.sps) {
      // Run through the supplier purchaser status codes, if one of the
      // categories is set to N (for "none"), just don't include it in the
      // SPS string. Absence of a category is treated as none.
      let val = this.sps[key];
      if(val === 'N') {continue;}
      codes.push(key+":"+val);
    }
    return codes.join(";");
  }

  set supplier_purchaser_status(str: string) {
    if(!str) {return;}
    let codeStrings = str.split(";");
    for(let i = 0; i < codeStrings.length; i++) {
      let status = codeStrings[i].split(":");
      this.sps[status[0]] = status[1];
    }
  }

  setSpsVal(cat: string, value: string): void {
    this.sps[cat] = value;
  }

  constructor(contact?: Object) {
    for(let key in contact) {
      this[key] = contact[key];
    }
  }
}
