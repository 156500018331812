import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'checkpipe'
})
export class CheckPipe implements PipeTransform {

  transform(value: any, args?: any): any {
    return value === "1" ? "X" : "";
  }

}
