import { Component, OnInit, Input, HostListener } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { VehicleService } from '../../services/vehicle.service';
import { UpdateLightsService } from '../../services/update-lights.service';
import { Subject, timer, EMPTY } from 'rxjs';
import { mergeMap, tap, debounce } from 'rxjs/operators';
import { environment } from '../../../environments/environment';

@Component({
  selector: 'app-images',
  templateUrl: './images.component.html',
  styleUrls: ['./images.component.scss']
})
export class ImagesComponent implements OnInit {
  images: Array<Object> = [];
  imagesNotYetUploaded: Array<Object> = [];
  imgID: number = 0;
  startImgOrder: number;
  activeImgOrder: number;
  @Input() vehid: string;
  @Input() vehdir: string;
  @Input() imgdir: string;
  descriptionsChanges: Subject<Object> = new Subject<Object>();
  loading: boolean = false;
  imageErrors: Array<Object>;
  imageUrls: Array<string> = [];
  totalFileSize: number = 0;

  // If we are in the middle of a re-ordering operation and they reload the
  // page, we will end up with out-of-order image descriptions.
  @HostListener('window:beforeunload')
  canDeactivate(): boolean {
    return (
      !this.updateLightsService.nextChangesComing() &&
      !this.updateLightsService.pendingChanges
    );
  }

  displayThumbnails = async (images) => {
    this.loading = true;
    this.imageUrls = [];

    let fetches = [];
    let blobs = [];
    let headers = new Headers();
    headers.append('Cache-Control', 'no-cache');

    // Fetch all thumbnails
    for(let i = 0; i < images['urls'].length; i++) {
      let fullsizeUrl = images["urls"][i].slice(0,images["urls"][i].indexOf("thumb"));
      fullsizeUrl += images["urls"][i].slice(images["urls"][i].indexOf("thumb-")+6);
      this.imageUrls.push(fullsizeUrl);
      // Delay each fetch so as to not be confronted by the Great Rate
      // Limiting Beast of Doom.
      fetches.push(await fetch(images['urls'][i], {headers: headers}));
    }

    // Then resolve blobs for all the thumbnails
    Promise.all(fetches).then(allFetches => {
      for(let i = 0; i < allFetches.length; i++) {
        blobs.push(allFetches[i].blob());
      }

      // Then feed each blob to a FileReader and resolve it as a data URL
      Promise.all(blobs).then(allBlobs => {
        for(let i = 0; i < allBlobs.length; i++) {
          let reader = new FileReader();
          reader.addEventListener("load", this.imageReaderFunction(reader, images['alts']));
          reader.readAsDataURL(allBlobs[i]);
        }
        this.loading = false;
      });
    });
  };

  // This function runs when the user uploads images
  handleImgUpload(event: any): void {
    this.imageErrors = [];
    let files = {...event.target.files};
    files["length"] = event.target.files.length;
    event.target.value = null;

    // Show errors and skip file reading for any images over 2 MB in size
    for(let i = 0; i < files.length; i++) {
      if(files[i].size > 2000000) {
        this.imageErrors.push({
          fileName: files[i].name,
          errorText: "filesize too large",
          explanation: "file must be below 2MB in size"
        });
        continue;
      }

      if(files[i].type !== "image/jpeg") {
        this.imageErrors.push({
          fileName: files[i].name,
          errorText: "wrong file type",
          explanation: "image must be JPG"
        });
        continue;
      }

      this.updateLightsService.nextChanges["imgUpload"] = true;
      this.totalFileSize += files[i]["size"];

      // Instantiate a FileReader to process the file and upload it to the server
      let reader = new FileReader();
      let len = files.length;

      reader.addEventListener("load", this.imageReaderFunction(reader, undefined, true, len, true, files[i]["name"]));
      reader.readAsDataURL(files[i]);
    }
  }

  imageReaderFunction(reader: FileReader, alts?: Array<string>, upload: boolean = false, len?: number, checkSize: boolean = false, fileName: string = ""): any {
    return async () => {
      let allowProcessing = true;
      if(checkSize) {
        let that = this;
        await new Promise(resolve => {
          let sizeCheckImg = new Image();
          sizeCheckImg.onload = function() {
            // Here, I implement a denial of upload rather than automatically
            // re-sizing on the server side. I do this to maintain the
            // functioning of the server-side anti-duplication check. If the
            // uploaded image were re-sized from source, we would lose future
            // protection against the uploading of duplicate images, since the
            // file data on the server would be different from the image on the
            // user's computer. This script, by its nature, is capable only of
            // preventing duplication within one upload.

            // Since it downloads thumbnails from the server, which themselves
            // are re-sized from the original, it is inherently incapable of
            // comparing newly uploaded images to their full-size server-side
            // counterparts. Therefore server-side checking is necessary, unless
            // I either stored full-size copies of all images on the server,
            // which would be a waste of space, or implemented per-image HTTP
            // requests to the server, with the full image data, to compare
            // against the full size image, which would be a waste of bandwidth.

            // For all these reasons, I have laid the responsibility of ensuring
            // correct image size firmly and forcibly upon the shoulders of the
            // user.
            if(this["height"] > 1200 || this["width"] > 1200) {
              that.imageErrors.push({
                fileName: fileName,
                errorText: "dimensions too large",
                explanation: "width and height must both be below 1200px"
              });
              allowProcessing = false;
            }
            resolve();
          };
          sizeCheckImg.src = reader.result;
        });
      }
      if(!allowProcessing) {return;}
      let img = {};
      // Don't allow the same image to be uploaded twice, at least in one upload.
      // The server will handle preventing duplicate uploads from actually
      // saving.
      for(let i = 0; i < this.images.length; i++) {
        if(this.images[i]['dataURL'] === reader.result) {
          return;
        }
      }
      for(let i = 0; i < this.imagesNotYetUploaded.length; i++) {
        if(this.imagesNotYetUploaded[i]["dataURL"] === reader.result) {
          return;
        }
      }
      img['trackByID'] = this.imgID++;
      img['dataURL'] = reader.result;
      img['order'] = this.images.length;
      img['description'] = alts ? alts[this.images.length] : undefined;
      if(!upload) {
        this.images.push(img);
      }

      else  {
        this.imagesNotYetUploaded.push(img);
        if(this.imagesNotYetUploaded.length === len) {
          this.uploadImagesToServer();
        }
      }
    }
  }

  // Called after processing all uploaded images. Sends their data off to the
  // server.
  uploadImagesToServer(index: number = 0): void {
    this.loading = true;
    this.updateLightsService.pendingChanges++;
    this.updateLightsService.nextChanges["imgUpload"] = false;

    // Upload the data URLs. The serves will clip out the base64 encoded
    // data and decode it to make the image files.
    let imageUrls = this.imagesNotYetUploaded.map(image => image['dataURL']);

    let uploadJSON = {
      "vehid": this.vehid,
      "image": imageUrls[index]
    };
    this.vehicleService.uploadImage(uploadJSON).subscribe(() => {
      this.updateLightsService.pendingChanges--;
      // Recursively upload one image at a time to make sure we don't send too
      // large of a payload, which will cause the upload operation to fail.
      if(++index !== this.imagesNotYetUploaded.length) {
        this.uploadImagesToServer(index);
      }
      else {
        this.imagesNotYetUploaded = [];
        // this.updateLightsService.pendingChanges--;
        this.vehicleService.getImageThumbnails(this.vehid).subscribe(this.displayThumbnails);
      }
    });
  }

  /*
    The drag-and-drop interface, implemented over the next three methods,
    provides for an eBay-style "displacement" method of drag-and-drop, whereby
    an image, having been moved, displaces other images to make a space for
    itself. This retains the existing order of the images not being moved and
    is less messy than the result of simply having images swap places, which was
    how this interface was originally implemented and which required more
    re-ordering operations to achieve the desired result.

    Thumbnail-sized images are absolutely essential for this interface to work
    as intended. The HTML5 drag-and-drop interface will simply show no ghost
    image at all if asked to display an image that is too large, because the
    animation will lag and provide a terrible user experience.
  */
  dragstart(event: any): void {
    this.startImgOrder = this.activeImgOrder = parseInt(event.target['dataset']['order']);

    // Hide the image, leaving only the drag ghost image. Makes it look like the
    // image has been lifted out of its spot. Must request animation frame
    // because if we do it without, the drag will not begin.
    window.requestAnimationFrame(() => {
      event.target.style.visibility = "hidden";
    });
  }

  dragenter(event: any): void {
    event.preventDefault();
    let draggedToImg = event.target;

    // Return if the event is firing on the same dropzone we are dragging from,
    // or if it's firing on an "image-dropzone" - because it will also fire on
    // the dropzone wrapper. We only want to do this once.
    if(
      draggedToImg['dataset']['order'] === this.activeImgOrder ||
      draggedToImg.classList.contains("image-dropzone")
    ) {return;}

    let newActiveImgOrder = parseInt(draggedToImg['dataset']['order']);

    // Same thing as server-side - start at the old image, and move the iterator
    // towards the new position, reaching ahead and pulling the next image into
    // the position currently pointed to by the iterator.
    let direction = newActiveImgOrder > this.activeImgOrder ? 1 : -1;

    this.images[this.activeImgOrder]['order'] = newActiveImgOrder;
    for(let i = this.activeImgOrder; i !== newActiveImgOrder; i += direction) {
      this.images[i+direction]['order'] = i;
    }

    this.activeImgOrder = newActiveImgOrder;
    this.updateLightsService.nextChanges["imgOrder"] = this.activeImgOrder === this.startImgOrder ? false : true;
    this.refreshImageOrder();
  }

  dragend(event: any): void {
    event.preventDefault();
    event.target.style.visibility = "visible";
    if(this.startImgOrder === this.activeImgOrder) {return;}

    let orderChange = {
      old: this.startImgOrder + 1,
      new: this.activeImgOrder + 1
    };

    this.updateLightsService.pendingChanges++;
    this.updateLightsService.nextChanges["imgOrder"] = false;

    this.vehicleService.updateImageOrder(this.vehid, this.vehdir, orderChange).subscribe(() => {
      this.updateLightsService.pendingChanges--;
      this.updateImageDescriptions(false);
    });
  }

  trackByFn(index: number, image: Object): any {
    return image['trackByID'];
  }

  refreshImageOrder(): void {
    this.images.sort((im1, im2) => {
      return im1['order'] - im2['order'];
    });
  }

  editDesc(number: string, value: string): void {
    this.images[number].description = value;
    this.updateImageDescriptions(true);
  }

  updateImageDescriptions(debounce: boolean): void {
    let descriptions = this.images.map(image => image['description']);
    this.descriptionsChanges.next({"debounce": debounce, "descriptions": descriptions});
  }

  deleteImage(order: number): void {
    this.images.splice(order, 1);
    for(let i = order; i < this.images.length; i++) {
      this.images[i]['order']--;
    }
    this.updateImageDescriptions(false);
    this.updateLightsService.pendingChanges++;
    this.vehicleService.deleteImage(this.vehid, (order+1).toString()).subscribe(() => {
      this.updateLightsService.pendingChanges--;
    });
  }

  openFullsize(order: number): void {
    let el = document.createElement("a");
    el.href = this.imageUrls[order];
    el.target = "_blank";
    el.rel = "noopener";
    el.id = "openlinkelement";

    document.body.appendChild(el);
    document.getElementById("openlinkelement").click();
    document.body.removeChild(el);
  }

  constructor(
    private vehicleService: VehicleService,
    private updateLightsService: UpdateLightsService,
    private router: Router,
    private route: ActivatedRoute
  ) { }

  ngOnInit() {
    this.descriptionsChanges.pipe(
      tap(() => this.updateLightsService.nextChanges["imgDescriptions"] = true),
      debounce(descriptions => descriptions["debounce"] ? timer(800) : EMPTY),
      tap(() => {
        this.updateLightsService.pendingChanges++;
        this.updateLightsService.nextChanges["imgDescriptions"] = false;
      }),
      mergeMap(descriptions => this.vehicleService.updateImageDescriptions(this.vehid, descriptions["descriptions"]))
    ).subscribe(() => this.updateLightsService.pendingChanges--);
    this.vehicleService.getImageThumbnails(this.vehid)
    .subscribe(this.displayThumbnails);
  }
}
