import { Component, OnInit, ViewChild, HostListener } from '@angular/core';
import { ComponentCanDeactivate } from '../pending-changes.guard';
import { Title } from '@angular/platform-browser';
import { Router, ActivatedRoute, NavigationStart, NavigationEnd } from '@angular/router';
import { ContactService } from '../services/contact.service';
import { Observable, Subject, of, timer, EMPTY, pipe} from 'rxjs';
import {
  tap,
  debounce,
  distinctUntilChanged,
  mergeMap,
  groupBy
} from 'rxjs/operators';
import { Contact } from '../contact';
import { ReminderService } from '../services/reminder.service';
import { Reminder } from '../reminder';
import { UpdateLightsService } from '../services/update-lights.service';
import { VehicleService } from '../services/vehicle.service';
import { ContactNotesComponent } from './contact-notes/contact-notes.component';
import { environment } from '../../environments/environment';

@Component({
  selector: 'app-contact-data',
  templateUrl: './contact-data.component.html',
  styleUrls: ['./contact-data.component.scss']
})
export class ContactDataComponent implements OnInit, ComponentCanDeactivate {
  @HostListener('window:beforeunload', ['$event'])
  canDeactivate(event): boolean {
    return event && this.updateLightsService.nextChangesComing() ? false : true;
  }

  @ViewChild(ContactNotesComponent) private contactNotes: ContactNotesComponent;

  constructor(
    private route: ActivatedRoute,
    private contactService: ContactService,
    private reminderService: ReminderService,
    private router: Router,
    private vehicleService: VehicleService,
    private updateLightsService: UpdateLightsService,
    private titleService: Title
  ) { }

  environment = environment;
  custid: string = "";
  contact: Contact;
  reminders: Reminder[] = [];
  noteBoxText: string = "";
  reminderSaved: boolean = false;
  editPipe = () => pipe(
    tap(_ => {
      this.updateLightsService.nextChanges[_["editType"]] = true;
    }),
    groupBy(_ => _["editType"]),
    mergeMap(_ => _.pipe(
      distinctUntilChanged(),
      debounce(_ => _["typeahead"] ? timer(1000) : EMPTY)
    )),
    tap(_ => {
      this.updateLightsService.nextChanges[_["editType"]] = false;
      this.updateLightsService.pendingChanges++;
    }),
    mergeMap(editItem => this.contactService.editContact(editItem))
  );
  editSubscriptionFunction = (editItem) => {
    this.updateLightsService.pendingChanges--;
    if(!this.route.snapshot.paramMap.get('custid') &&
    this.updateLightsService.pendingChanges===0 &&
    !this.updateLightsService.nextChangesComing()
    ) {
      this.router.navigateByUrl("/contactdata/"+this.custid);
    }
    if(!editItem.typeahead && editItem.updateAfterEdit) {
      this.updateContact(editItem.editType);
    }
  }

  // I will try to standardize this rxjs Subject
  // to work with every edit on the page, so we
  // can just push more and more edits onto the stack
  // and have them go through a single pipeline in a
  // standard way.
  private edits = new Subject<Object>();

  // The edits subject is piped to process
  // a JSON object. These names are understood
  // by the server-side endpoint.

  editItem(editType: string, editText: string | boolean, typeahead: boolean = false, updateAfterEdit: boolean = false): void {
    // if(this.custid==="NEW") {
    //   this.contactService.generateNewContactId()
    //   .subscribe(newid => {
    //     this.custid = newid;
    //     this.editItem(editType, editText, typeahead);
    //   });
    //   return;
    // }
    let noteid = "";
    if(editType === "editnote") {
      noteid = editText["slice"](0, editText["indexOf"]("&"));
      editText = editText["slice"](editText["indexOf"]("&")+1);
    }
    let editItem = {
      "custid": this.custid,
      "editType": editType,
      "editText": editText,
      "typeahead": typeahead,
      "updateAfterEdit": updateAfterEdit
    };
    if(noteid) {
      editItem["noteid"] = noteid;
    }
    this.edits.next(editItem);
  }

  toggle(field: string, value: boolean): void {
    this.editItem(field, value);
  }

  noteBoxTextChanged(noteBoxText: string): void {
    this.noteBoxText = noteBoxText;
    if(noteBoxText !== "") {this.updateLightsService.nextChanges["notebox"]=true;}
    else {this.updateLightsService.nextChanges["notebox"]=false;}
  }

  editSps(sps: Object): void {
    this.contact.setSpsVal(sps["cat"], sps["value"]);
    this.editItem("supplier_purchaser_status", this.contact.supplier_purchaser_status, false, false);
  }

  updateContact(editType: string): void {
    let editTypeToContactPropertyMap = {
      "newnote": "notes",
      "editnote": "notes",
      "addinterestedvehicle": "vehicles_interested",
      "removeinterestedvehicle": "vehicles_interested",
      "ourbus": "our_bus",
      "transactionstage": "transaction_stage",
    };
    let fieldToUpdate = editType in editTypeToContactPropertyMap ? editTypeToContactPropertyMap[editType] : editType;
    this.contactService.getDataField(fieldToUpdate, this.contact.id)
    .subscribe(newData => {
      this.contact[fieldToUpdate] = newData;
      if(fieldToUpdate === 'notes') {
        this.contactNotes.refreshNotes();
      }
      // if(fieldToUpdate === "vehicles_interested") {
      //   this.updateContact("our_vehicle");
      // }
    });
  }

  // Used to populate contact data.
  getContact(): void {
    this.custid = this.route.snapshot.paramMap.get('custid');
    this.contactService.getContactById(this.custid)
    .subscribe(c => {
      this.contact = c;
      // this.contactNotes.refreshNotes();
      this.titleService.setTitle("Contact: " + c.name);
    });
  }

  // Subscribe edits to push loaded edits through the edit service and then
  // update the appropriate variables.
  ngOnInit() {
    this.edits.pipe(this.editPipe()).subscribe(this.editSubscriptionFunction);

    this.getContact();

    this.router.events.subscribe(event => {
      if(event instanceof NavigationStart) {
        this.updateLightsService.nextChanges["notebox"]=false;
      }
      else if(event instanceof NavigationEnd && this.route.snapshot.paramMap.get('custid') !== this.custid) {
        this.getContact();
      }
    });
  }
}
