import { Component, OnInit, Input, ElementRef } from '@angular/core';
import { Router } from '@angular/router';
import { Contact } from '../../contact';
import { AutolinkerService } from '../../services/autolinker.service';
import { environment } from '../../../environments/environment';

@Component({
  selector: 'app-vehicle-contact-notes',
  templateUrl: './vehicle-contact-notes.component.html',
  styleUrls: ['./vehicle-contact-notes.component.scss']
})
export class VehicleContactNotesComponent implements OnInit {
  @Input() notes: Array<Object>;
  notesToDisplay: Array<Object>;
  loading: boolean = false;
  loadingText: string = "Loading";
  environment = environment;

  refreshNotes(): void {
    this.loading = true;
    let interval = setInterval(() => {
      switch(this.loadingText) {
        case "Loading":
          this.loadingText = "Loading.";
          break;
        case "Loading.":
          this.loadingText = "Loading..";
          break;
        case "Loading..":
          this.loadingText = "Loading...";
          break;
        case "Loading...":
          this.loadingText = "Loading";
          break;
      }
    }, 400);
    setTimeout(() => {
      this.notesToDisplay = [];
      let notes = JSON.parse(JSON.stringify(this.notes));
      let noteTexts = [];
      for(let i = 0; i < notes.length; i++) {
        noteTexts.push(notes[i]["text"]);
      }

      let results = this.autolinkerService.autolink(noteTexts, this.elRef.nativeElement['localName']);

      new Promise(async resolve => {
        let linkedNotes = [];
        for(let i = 0; i < results.length; i++) {
          let linkedNote = await results[i]();
          notes[i]["text"] = linkedNote["text"];
          notes[i]["links"] = linkedNote["links"];
          notes[i]["order"] = linkedNote["order"];
          linkedNotes.push(notes[i]);
        }
        resolve(linkedNotes);
      }).then(linkedNotes => {
        this.loading = false;
        clearInterval(interval);
        for(let i = 0; i < linkedNotes["length"]; i++) {
          this.notesToDisplay.push(linkedNotes[i]);
          this.autolinkerService.addLinkListeners(linkedNotes[i]["links"], this.elRef, this.router);
        }
        this.notesToDisplay.sort((a, b) => {
          return a["order"] - b["order"];
        });
      });
    }, 0);
  }

  constructor(
    private router: Router,
    private autolinkerService: AutolinkerService,
    private elRef: ElementRef
  ) { }

  ngOnInit() {
    this.refreshNotes();
  }

}
