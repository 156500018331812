import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { Router } from '@angular/router';
import { Observable, Subject, of } from 'rxjs';
import {
  tap,
  debounceTime,
  distinctUntilChanged,
  switchMap
} from 'rxjs/operators';
import { ContactService } from '../../services/contact.service';
import { UpdateLightsService } from '../../services/update-lights.service';
import { environment } from '../../../environments/environment';

@Component({
  selector: 'app-owner',
  templateUrl: './owner.component.html',
  styleUrls: ['./owner.component.scss']
})
export class OwnerComponent implements OnInit {
  @Input() owner: any = {
    unsubscribe_confirmations: <string> null,
    bounced_email: <string> null,
    invalid_email: <string> null,
    id: <string> null
  };
  @Input() ownerSearchResults: Array<Object> = [];
  @Output() typeahead: EventEmitter<Object> = new EventEmitter<Object>();
  @Output() setOwner: EventEmitter<string> = new EventEmitter<string>();
  addNewOwner: boolean = false;
  newOwner: Object = {};
  private searchTerms = new Subject<Object>();
  showAllFields: boolean = false;
  environment = environment;

  getRouter(): Router {
    return this.router;
  }

  searchContacts(searchTerm: string) {
    if(!searchTerm) {
      this.ownerSearchResults = [];
      return;
    }
    if(/\+?((\d+)-(\d*))+/.test(searchTerm)) {
      searchTerm = searchTerm.replace(/-/g, '');
    }
    let search = {
      "searchTerm": searchTerm,
      "searchNotes": false,
      "filters": {
        "stage": "all",
        "offset": 0
      }
    };

    this.searchTerms.next(search);
  }

  setOwnerId(newId: string, ownerSearchBox: HTMLElement) {
    ownerSearchBox['value'] = '';
    this.ownerSearchResults = [];
    this.setOwner.emit(newId);
  }

  setOwnerData(type: string, value: string): void {
    if(type === "phone" ||
      type === "phone2" ||
      type === "cphone") {
      value = value.replace(/[^\d]/g, "");
      }
    if(this.owner && this.owner['id']) {
      let event = {
        type: type,
        value: value.trim()
      };
      event['ownerid'] = this.owner['id'];

      this.typeahead.emit(event);
    }
    else {
      this.newOwner[type] = value;
    }
  }

  addOwner(): void {
    this.contactService.addNewOwner(this.newOwner).subscribe(newId => {
      this.setOwner.emit(newId);
    });
  }

  constructor(
    private contactService: ContactService,
    private updateLightsService: UpdateLightsService,
    private router: Router
  ) { }

  ngOnInit() {
    this.searchTerms.pipe(
      //We check distinctUntilChanged and debounceTime, then we
      //run a filter to make sure the phone is a number
      //only.  The order here is important:  If we do not
      //let distinctUntilChanged see the input first, then
      //when the user backspaces all their input, the last
      //digit they backspaced will be stuck in the pipe.
      //If they type that same digit again, distinctUntilChanged
      //will fail and the search will not execute.
      // distinctUntilChanged(),
      debounceTime(400),
      tap(_ => {
        // this.loading = true;
      }),
      switchMap(searchTerm => this.contactService.searchContacts(searchTerm))
    )
    .subscribe(response => {
      this.ownerSearchResults = response;
    });
  }

}
