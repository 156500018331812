import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, Subject, of } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class UserNotesService {

  private notesSubject: Subject<Array<Object>> = new Subject<Array<Object>>();
  notes: Observable<Array<Object>> = this.notesSubject.asObservable();

  headers = {
    headers: new HttpHeaders({
      "Content-Type": "application/x-www-form-urlencoded"
    })
  };

  getUserNotes(): void {
    this.http.get<any>(environment.apiRoot + '/usernotes/get-user-notes.php')
    .pipe(
      catchError(error => {
        console.log(error);
        return of("");
      })
    ).subscribe(notes => this.notesSubject.next(notes));
  }

  addNote(noteText: string): void {
    let note = {
      "note": noteText
    };
    this.http.post<any>(environment.apiRoot + "/usernotes/add-user-note.php", note, this.headers).pipe(
      catchError(error => {
        console.log(error);
        return of();
      })
    ).subscribe(() => this.getUserNotes());
  }

  deleteNote(noteId: string): void {
    this.http.post<any>(environment.apiRoot + "/usernotes/delete-user-note.php", `noteid=${noteId}`, this.headers).pipe(
      catchError(error => {
        console.log(error);
        return of();
      })
    ).subscribe(() => this.getUserNotes());
  }

  editNote(noteId: string, newText: string): void {
    let edit = {
      noteid: noteId,
      newtext: newText
    }
    this.http.post<any>(environment.apiRoot + "/usernotes/edit-user-note.php", edit, this.headers).pipe(
      catchError(error => {
        console.log(error);
        return of();
      })
    ).subscribe(() => this.getUserNotes());
  }

  constructor(private http: HttpClient) { }
}
