import { Injectable, ElementRef } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { VehicleService } from './vehicle.service';
import { ContactService } from './contact.service';
import { Observable } from 'rxjs';
import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class AutolinkerService {
  vehidCache: Array<string> = [];
  contactCache: Array<Object> = [];
  environment = environment;

  autolink(notes: Array<string>, localName: string): Array<Function> {
    let notePromises = [];

    for(let i = 0; i < notes.length; i++) {
      // Take a note, split it into individual words, analyze each word to see
      // if it matches a pattern to be linked as a contact or stock number,
      // then add a promise that will resolve with an object containing that
      // new link's appropriate position in the note text, an ID for it,
      // the clickable text for the link, and the URL to which it should point.

      // The code calling this function should .then the result and then
      // change the note's text to the text value in the object resolved from the
      // promise returned by this function. It must then pass the resolved links
      // array to the addLinkListeners function in this service, along with its
      // ElementRef and Router. addLinkListeners will then bind an event listener
      // to each ID, and have it call the router.navigateByUrl method directed to
      // the URL indicated in the resolved object.

      // This allows automatic creation of links based on key words and patterns
      // in notes that link to a given vehicle or contact profile page using the
      // Angular router, without the need to fully refresh the browser, as would
      // be in the case of the use of simple anchor tags.
      let note = notes[i];
      note = note.replace(/(\n+)/g, "$1 ");
      let words = note.split(' ');
      let wordPromises = [];

      for(let k = 0; k < words.length; k++) {
        let word = words[k];
        let isLastWord = k === words.length - 1;
        let stockNumRegExp = /([^cC]|^)#\d+/;
        let custNumRegExp = /c#\d+/i;

        let wordResolveValue = {
          noteNumber: i,
          position: k,
          get id() {return this._id;},
          set id(id) {
            this._id = "autolink"+id+localName+i+k;
          }
        };

        // The async/await pattern is to rate-limit the HTTP requests. We do one
        // at a time, lest the whole thing come a-crashing down. We minimize
        // HTTP requests by caching valid IDs.
        if(stockNumRegExp.test(word)) {
          wordPromises.push(async () => {
            let id = this.vehidCache.find(vehid => vehid === word.replace(/[^\d]/g, '')) ||
            await new Promise(resolve => {
              this.vehicleService.checkVehicleId(word.replace(/[^\d]/g, ''))
              .subscribe(id => {
                this.vehidCache.push(id);
                resolve(id);
              });
            });
            if(!id) {return null;}

            let linkMatch = word.match(stockNumRegExp);
            let linkWord = linkMatch[0].replace(/[^#\d]/g, '');
            let idWord = linkMatch[0].replace(/[^\d]/g, '');

            let otherStuffBefore = word.match(/([\s\S]*)#\d+/)[1];
            let otherStuffAfter = word.match(/#\d+([\s\S]*)/)[1];

            wordResolveValue["url"] = "/vehicledata/"+id;
            wordResolveValue["id"] = idWord;
            wordResolveValue["linkElement"] = otherStuffBefore+'<span id="'+wordResolveValue["id"]+'" class="link-button">' + linkWord + '</span>'+otherStuffAfter;

            return wordResolveValue;
          });
        }

        else if(custNumRegExp.test(word)) {
          wordPromises.push(async () => {
            let contact = this.contactCache.find(contact => contact["id"] === word.replace(/[^\d]/g, '')) ||
            await new Promise(resolve => {
              this.contactService.checkContactId(word.replace(/[^\d]/g, ''))
              .subscribe(contact => {
                this.contactCache.push({
                  id: contact.id,
                  name: contact.name
                });
                resolve(contact);
              });
            });
            if(!contact) {return null;}

            let linkMatch = word.match(custNumRegExp);
            let linkWord = linkMatch[0];
            let contactName = linkWord.replace(linkWord, contact["name"]);

            let otherStuffBefore = word.match(/([\s\S]*)c#\d+/i)[1];
            let otherStuffAfter = word.match(/c#\d+([\s\S]*)/i)[1];

            wordResolveValue["url"] = "/contactdata/"+contact["id"];
            wordResolveValue["id"] = linkMatch[0].replace(/#/g, '');
            wordResolveValue["linkElement"] = otherStuffBefore+'<span id="'+wordResolveValue["id"]+'" class="link-button">' + contactName + '</span>'+otherStuffAfter;

            return wordResolveValue;
          });
        }
      }

      notePromises.push(async resolve => {
        let noteResolveValue = {
          text: "",
          links: [],
          order: i
        };
        for(let k = 0; k < wordPromises.length; k++) {
          let currentWord = await wordPromises[k]();
          if(!currentWord) {continue;}

          words[currentWord["position"]] = currentWord["linkElement"];

          noteResolveValue["links"].push({
            url: currentWord["url"],
            id: currentWord["id"],
          });
        }

        noteResolveValue["text"] = words.join(' ');

        // Do away with the space that will exist after line returns.
        noteResolveValue["text"] = noteResolveValue["text"].replace(/\n\n\s/g, "\n\n");
        return noteResolveValue;
      });
    }
    return notePromises;
  }

  addLinkListeners(linkInfos: Array<Object>, elRef: ElementRef, router: Router): void {
    for(let i = 0; i < linkInfos.length; i++) {
      let currentLink = linkInfos[i];

      setTimeout(() => {
        // If the user quickly navigates away, the querySelector will fail and
        // we will get a console error when this method attempts to bind a
        // listener to an element that does not exist. While not a huge deal,
        // I don't like errors, so I do this instead.
        let linkElement = elRef.nativeElement.querySelector("#"+currentLink["id"]);
        if(!linkElement) {return;}
        linkElement.addEventListener('click', event => this.environment.openRoute(currentLink["url"], event, router));
      }, 0);
    }
  }

  constructor(
    private vehicleService: VehicleService,
    private contactService: ContactService,
    private route: ActivatedRoute
  ) { }
}
