import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-slider-switch',
  templateUrl: './slider-switch.component.html',
  styleUrls: ['./slider-switch.component.scss']
})
export class SliderSwitchComponent implements OnInit {
  @Input() switchOn: boolean = false;
  @Input() inline: boolean = false;
  @Output() toggle: EventEmitter<boolean> = new EventEmitter<boolean>();

  toggleSlider(): void {
    this.switchOn = !this.switchOn;
    this.toggle.emit(this.switchOn);
  }

  constructor() { }

  ngOnInit() {
  }

}
