import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class SuppliersService {
  getSuppliersApi: string = environment.apiRoot + "/suppliers/get-suppliers.php";
  updateSupplierApi: string = environment.apiRoot + "/suppliers/update-supplier.php";
  addSupplierApi: string = environment.apiRoot + "/suppliers/add-supplier.php";

  postHeaders = {
    headers: new HttpHeaders({
      "Content-Type": "application/x-www-form-urlencoded"
    })
  };

  get suppliers(): Observable<Object> {
    return this.http.get<Array<Object>>(this.getSuppliersApi).pipe(
      catchError(error => {
        console.log(error);
        return of([""]);
      })
    );
  }

  updateSupplier(id: string, field: string, value: string): Observable<any> {
    return this.http.post<any>(this.updateSupplierApi, `id=${id}&field=${field}&value=${value}`, this.postHeaders).pipe(
      catchError(error => {
        console.log(error);
        return of([""]);
      })
    );
  }

  addSupplier(newSupplier: Object): Observable<any> {
    return this.http.post<any>(this.addSupplierApi, JSON.stringify(newSupplier), this.postHeaders).pipe(
      catchError(error => {
        console.log(error);
        return of("");
      })
    );
  }

  constructor(
    private http: HttpClient
  ) { }
}
