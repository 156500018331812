import { Component, Input, OnInit } from '@angular/core';
import { Router, ActivatedRoute, NavigationEnd } from '@angular/router';
import { LoginService } from './services/login.service';
import { JwtHelperService } from '@auth0/angular-jwt';
import { MobileNavService } from './services/mobile-nav.service';
import { environment } from '../environments/environment';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
  constructor(private loginService: LoginService,
    private jwtHelper: JwtHelperService,
    private router: Router,
    private route: ActivatedRoute,
    private mobileNavService: MobileNavService) {}

  loggedIn: boolean = false;
  username_error: boolean = false;
  password_error: boolean = false;

  ngOnInit () {
    this.loggedIn = this.loginService.isLoggedIn;

    // Scroll to the top whenever the user follows a link to a new screen.
    this.router.events.subscribe(event => {
      if(event instanceof NavigationEnd) {
        if(!this.loginService.isLoggedIn) {
          this.loginService.logout();
          return;
        }
        window.scrollTo(0,0);
      }
    });
  }

  overlayCloseNav(): void {
    if(this.mobileNavService.navOpen) {
      this.mobileNavService.navOpen = false;
    }
  }

  login(username: string, password: string): void {
    this.username_error = false;
    this.password_error = false;
    if(!username) {this.username_error = true;}
    if(!password) {this.password_error = true;}
    if(this.username_error || this.password_error) {return;}

    this.loginService.login(username,password)
    .subscribe(jwt => {
      let data = this.jwtHelper.decodeToken(jwt);
      if(data) {
        this.loggedIn = true;
        localStorage.setItem("access_token", jwt);
        localStorage.setItem("name", data["name"]);
        localStorage.setItem("userid", data["userid"]);
        
        // Tom wants to see the search buses screen on login
        let redirectUrl = data["userid"] === "5" ? "vehiclesearch" : this.loginService.redirectUrl;
        this.router.navigateByUrl(redirectUrl);
      }
    })
  }
}
