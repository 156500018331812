import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { Contact } from '../../contact';
import { environment } from '../../../environments/environment';

@Component({
  selector: 'app-basic-info-table',
  templateUrl: './basic-info-table.component.html',
  styleUrls: ['./basic-info-table.component.scss']
})
export class BasicInfoTableComponent implements OnInit {
  showAllDetails: boolean = false;
  @Input() contact: Contact;
  @Output() typeahead: EventEmitter<Object> = new EventEmitter<Object>();
  environment = environment;

  emitTypeahead(input: string, type: string): void {
    if(type === "phone" ||
    type === "cphone" ||
    type === "phone2" ||
    type === "fax" ||
    type === "fax2") {
      input = input.replace(/[^\d+]/g, '');
    }

    input = input.trim();
    let event = {
      "type": type,
      "value": input
    };
    this.typeahead.emit(event);
  }

  constructor() { }

  ngOnInit() {
  }

}
