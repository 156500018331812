import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { catchError, tap, mergeMap } from 'rxjs/operators';
import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class AutoconfirmatorService {
  environment = environment;
  sendMassConfirmationUrl: string = environment.apiRoot + "/autoconfirmator/send-mass-confirmation.php";
  sendIndividualConfirmationUrl: string = environment.apiRoot + "/autoconfirmator/send-individual-confirmation.php";
  getConfirmationsLogUrl: string = environment.apiRoot + "/autoconfirmator/get-log.php";
  getOutstandingUrl: string = environment.apiRoot + "/autoconfirmator/get-outstanding.php";
  getUsersWithoutEmailUrl: string = environment.apiRoot + "/autoconfirmator/get-users-without-email.php";
  getUnsubscribedUsersUrl: string = environment.apiRoot + "/autoconfirmator/get-unsubscribed-users.php";
  getBouncedUsersUrl: string = environment.apiRoot + "/autoconfirmator/get-bounced.php";
  getVehsWithDifferentOwnerPriceUrl: string = environment.apiRoot + "/autoconfirmator/get-vehicles-with-different-owner-price.php";
  setAvailabilityConfirmedUrl: string = environment.apiRoot + "/autoconfirmator/set-availability-confirmed.php";
  switchNoConfirmUrl: string = environment.apiRoot + "/autoconfirmator/switch-no-confirm.php";

  postHeaders = {
    headers: new HttpHeaders({
      "Content-Type": "application/x-www-form-urlencoded"
    })
  };

  sendMassConfirmation(): Observable<any> {
    return this.http.get<any>(this.sendMassConfirmationUrl).pipe(
      catchError(error => {
        console.log(error);
        return of("");
      })
    );
  }

  get confirmationsLog(): Observable<Array<Object>> {
    return this.http.get<Array<Object>>(this.getConfirmationsLogUrl).pipe(
      catchError(err => {
        console.log(err);
        return of([]);
      })
    );
  }

  sendIndividualConfirmation(vehid: string): Observable<string> {
    return this.http.post<string>(this.sendIndividualConfirmationUrl, `vehid=${vehid}`, this.postHeaders).pipe(
      catchError(error => {
        console.log(error);
        return of("");
      })
    );
  }

  get outstanding(): Observable<Array<Object>> {
    return this.http.get<Array<Object>>(this.getOutstandingUrl).pipe(
      catchError(error => {
        console.log(error);
        return of([{}]);
      })
    );
  }

  get usersWithoutEmail(): Observable<Array<Object>> {
    return this.http.get<Array<Object>>(this.getUsersWithoutEmailUrl).pipe(
      catchError(error => {
        console.log(error);
        return of([{}]);
      })
    );
  }

  get unsubscribedUsers(): Observable<Array<Object>> {
    return this.http.get<Array<Object>>(this.getUnsubscribedUsersUrl).pipe(
      catchError(error => {
        console.log(error);
        return of([{}]);
      })
    );
  }

  get bouncedUsers(): Observable<Array<Object>> {
    return this.http.get<Array<Object>>(this.getBouncedUsersUrl).pipe(
      catchError(error => {
        console.log(error);
        return of([{}]);
      })
    );
  }

  get vehsWithDifferentOwnerPrice(): Observable<Array<Object>> {
    return this.http.get<Array<Object>>(this.getVehsWithDifferentOwnerPriceUrl).pipe(
      catchError(error => {
        console.log(error);
        return of([{}]);
      })
    );
  }

  setAvailabilityConfirmed(vehid: string): Observable<string> {
    return this.http.post<string>(this.setAvailabilityConfirmedUrl, `vehid=${vehid}`, this.postHeaders).pipe(
      catchError(error => {
        console.log(error);
        return of("");
      })
    );
  }

  switchNoConfirm(vehid: string, switchState: string): Observable<any> {
    return this.http.post<any>(this.switchNoConfirmUrl, `vehid=${vehid}&switchState=${switchState}`, this.postHeaders).pipe(
      catchError(error => {
        console.log(error);
        return of();
      })
    );
  }

  constructor(
    private http: HttpClient
  ) { }
}
