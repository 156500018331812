import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpInterceptor, HttpErrorResponse, HttpRequest, HttpEvent, HttpHandler } from '@angular/common/http';
import { Observable, of, pipe } from 'rxjs';
import { catchError, tap, mergeMap } from 'rxjs/operators';
import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class LoginService {
  loginApi: string = environment.apiRoot + "/admin-login.php";
  redirectUrl: string = "";

  /* This HTTP interceptor performs two functions:
    1. Automatically log the user out if the authentication token has decayed.
    2. Pre-authorize POST requests to foil IP spoof attacks.

    We authenticate JWT requests by tying them to a specific IP address. If the
    JWT were stolen, and the associated IP address spoofed, an attacker could
    authorize requests against the administrative API.

    In the case of a GET request, the server will send the response back to the
    spoofed - i.e., legitimate - IP address, so the attacker will never see any
    response data.

    However, a POST request could be executed by the server, even without the
    attacker seeing any response data.

    To foil this angle of attack, we pre-authorize POST requests with a one-time,
    randomly-generated token associated with the JWT and which is valid for only
    one minute. This means that a server response is needed before a POST
    request will be permitted.

    Since an IP spoof attack cannot force the server to return its response to
    the attacker, this pre-authorization should render spoof attacks useless.
  */
  // intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
  //   let logoutPipe = () => pipe(
  //     catchError(resp => {
  //       if(resp instanceof HttpErrorResponse && resp.status === 401) {
  //         // this.logout(false);
  //       }
  //       return of(null);
  //     })
  //   );
  //   if(req.method === "POST" && req.url !== this.loginApi) {
  //     return this.http.get<any>(environment.apiRoot + "/get-preauth-token.php").pipe(
  //       mergeMap(token => {
  //         let headers = req.headers;
  //         headers = headers.append('X-Preauth-Token', token);
  //         let newReq = req.clone({headers: headers});
  //         return next.handle(newReq).pipe(logoutPipe());
  //       }),
  //       catchError(err => {
  //         console.log(err);
  //         return of(null);
  //       })
  //     );
  //   }
  //   else {
  //     return next.handle(req).pipe(logoutPipe());
  //   }
  // }

  // intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
  //   return next.handle(req).pipe(
  //     catchError(resp => {
  //       if(resp instanceof HttpErrorResponse && resp.status === 401) {
  //         this.logout(false);
  //       }
  //       return of(null);
  //     })
  //   );
  // }

  get isLoggedIn(): boolean {
    let currentJwt = localStorage.getItem("access_token");
    // PHP stores the expiration time in seconds rather than miliseconds, so we
    // must divide the current Epoch time in miliseconds by 1,000 to get the
    // right comparison.

    // Currently, expiration is disabled, because it pretty much just annoys
    // everyone here.
    if(currentJwt
      // && parseInt(localStorage.getItem("bfsms_exp")) > Math.round(Date.now() / 1000)
    ) {
      return true;
    }
    return false;
  }

  httpOptions = {
    headers: new HttpHeaders({
      "Content-Type": "application/x-www-form-urlencoded"
    })
  };

  login(username: string, password: string): Observable<string> {
    return this.http.post<string>(this.loginApi, `username=${username}&password=${password}`, this.httpOptions)
    .pipe(
      catchError(err => {
        console.log(err);
        return of("");
      })
    )
  }

  logout(confirm: boolean = true): void {
    if(confirm && !window.confirm("Are you sure you want to log out?")) {return;}
    localStorage.clear();
    sessionStorage.clear();
    location.reload();
  }

  constructor(private http: HttpClient) { }
}
