import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { DashboardComponent } from './dashboard/dashboard.component';
import { ContactSearchComponent } from './contact-search/contact-search.component';
import { ContactDataComponent } from './contact-data/contact-data.component';
import { ShowAllContactsComponent } from './show-all-contacts/show-all-contacts.component';
import { VehicleSearchComponent } from './vehicle-search/vehicle-search.component';
import { VehicleDataComponent } from './vehicle-data/vehicle-data.component';
import { InventoryComponent } from './inventory/inventory.component';
import { VehicleSubmissionsComponent } from './vehicle-submissions/vehicle-submissions.component';
import { SuppliersComponent } from './suppliers/suppliers.component';
import { PurchasersComponent } from './purchasers/purchasers.component';
import { AutoconfirmatorComponent } from './autoconfirmator/autoconfirmator.component';
import { AuthGuard } from './auth.guard';
import { SaveChangesGuard } from './save-changes.guard';
import { AddVehicleComponent } from './add-vehicle/add-vehicle.component';
import { AddContactComponent } from './add-contact/add-contact.component';
import { PendingChangesGuard } from './pending-changes.guard';

const routes: Routes = [
  { path: 'dashboard', component: DashboardComponent, canActivate: [AuthGuard], canDeactivate: [PendingChangesGuard] },
  { path: 'contactsearch', component: ContactSearchComponent, canActivate: [AuthGuard] },
  { path: 'contactdata', component: ContactDataComponent, canActivate: [AuthGuard], canDeactivate: [PendingChangesGuard] },
  { path: 'contactdata/:custid', component: ContactDataComponent, canActivate: [AuthGuard], canDeactivate: [PendingChangesGuard] },
  { path: 'addcontact', component: AddContactComponent, canActivate: [AuthGuard] },
  { path: 'showallcontacts', component: ShowAllContactsComponent, canActivate: [AuthGuard] },
  { path: 'vehiclesearch', component: VehicleSearchComponent, canActivate: [AuthGuard] },
  { path: 'addvehicle', component: AddVehicleComponent, canActivate: [AuthGuard] },
  { path: 'vehiclesubmissions', component: VehicleSubmissionsComponent, canActivate: [AuthGuard] },
  { path: 'submittedvehicle/:vehid', component: VehicleSubmissionsComponent, canActivate: [AuthGuard], canDeactivate: [PendingChangesGuard] },
  { path: 'vehicledata/:vehid', component: VehicleDataComponent, canActivate: [AuthGuard], canDeactivate: [PendingChangesGuard] },
  { path: 'suppliers', component: SuppliersComponent, canActivate: [AuthGuard], canDeactivate: [PendingChangesGuard] },
  { path: 'purchasers', component: PurchasersComponent, canActivate: [AuthGuard], canDeactivate: [PendingChangesGuard] },
  { path: 'inventory', component: InventoryComponent, canActivate: [AuthGuard], canDeactivate: [PendingChangesGuard] },
  { path: 'autoconfirmator', component: AutoconfirmatorComponent, canActivate: [AuthGuard] },
  { path: '', redirectTo: 'dashboard', pathMatch: 'full' }
];

@NgModule({
  exports: [ RouterModule ],
  imports: [ RouterModule.forRoot(routes) ],
})
export class AppRoutingModule { }
