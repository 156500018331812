import { Component, OnInit, ViewChild, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-wysiwyg-editor',
  templateUrl: './wysiwyg-editor.component.html',
  styleUrls: ['./wysiwyg-editor.component.scss']
})
export class WysiwygEditorComponent implements OnInit {

  linkDialogue: boolean = false;
  linktext_error: boolean = false;
  linkurl_error: boolean = false;
  @Input() content: string;
  @ViewChild('editor') editor;
  @Output() typeahead: EventEmitter<string> = new EventEmitter<string>();

  emitTypeahead(event?: any): void {
    if(event && event.inputType === '') {return;}
    // If they backspace an empty text editor, it will delete the last <p> tag
    // and prevent entering any more non-formatted plain text content without
    // first entering some formatted content. If a backspace is pressed on an
    // empty editor, we must replace the <p> element that will be deleted.

    if(event &&
      event.inputType === "deleteContentBackward" &&
      this.editor.nativeElement.textContent === ""
    ) {
      document.execCommand("formatBlock", false, "p");
    }
    this.typeahead.emit(this.editorContents);
  }

  toggleControl(controlName: string, event: any): void {
    event.preventDefault();
    document.execCommand(controlName);
  }

  tabKey(event: any): void {
    if(event.key !== "Tab") {return;}
    event.preventDefault();

    if(event.shiftKey) {
      document.execCommand('outdent');
    }
    else {
      document.execCommand('indent');
    }
  }

  insertLink(text: string, url: string): void {
    if(this.linktext_error) {this.linktext_error = false;}
    if(this.linkurl_error) {this.linkurl_error = false;}
    if(!text) {this.linktext_error = true;}
    if(!url) {this.linkurl_error = true;}
    if(this.linktext_error || this.linkurl_error) {return;}

    this.editor.nativeElement.focus();
    if(!/^http(s)?:\/\//.test(url)) {
      url = "http://" + url;
    }
    document.execCommand("createLink", false, url);
    let link = document.getSelection().anchorNode.parentElement;
    link.textContent=text;
    link.setAttribute("target", "_blank");
    link.setAttribute("contenteditable", "false");
    // link.setAttribute('onclick','if(event.ctrlKey){window.open(this.href,"_blank");}');
    this.linkDialogue = false;
    this.emitTypeahead();
  }

  get editorContents(): string {
    let dom = new DOMParser().parseFromString(this.editor.nativeElement.innerHTML, "text/html");
    let processedString = "";

    for(let i = 0; i < dom.body.childNodes.length; i++) {
      let child = dom.body.childNodes[i];
      // The old admin system stored notes as plain text rather than HTML elements.
      // If a note is plain text, we convert it to a paragraph element.
      if(child['nodeType'] === 3) {
        processedString += "<p>" + child['textContent'] + "</p>";
      }
      else if(child['innerText']) {
        processedString += child['outerHTML'];
      }
    }
    return processedString;
  }

  showContents(): void {
    console.log(this.editorContents);
  }

  constructor() { }

  ngOnInit() {
    // Need to time this out because otherwise the innerHTML data binding in the
    // template will override what this has done when it replaces the contents
    // with the value of this.content.
     setTimeout(() => {
      this.editor.nativeElement.focus({preventScroll: true});
      if(!this.editor.nativeElement.textContent) {document.execCommand("formatBlock", false, "p");}
      document.execCommand("defaultParagraphSeparator", false, "p");
      this.editor.nativeElement.blur();
    }, 200);
  }
}
