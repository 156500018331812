import { Component, OnInit, ViewChildren, QueryList, ViewChild, ElementRef, HostListener } from '@angular/core';
import { ComponentCanDeactivate } from '../pending-changes.guard';
import { Title } from '@angular/platform-browser';
import { InventoryService } from '../services/inventory.service';
import { InvTableComponent } from './inv-table/inv-table.component';
import { UpdateLightsService } from '../services/update-lights.service';
import { Observable, Subject, of, pipe, EMPTY, timer } from 'rxjs';
import { debounce, distinctUntilChanged, tap, groupBy, mergeMap } from 'rxjs/operators';

@Component({
  selector: 'app-inventory',
  templateUrl: './inventory.component.html',
  styleUrls: ['./inventory.component.scss']
})
export class InventoryComponent implements OnInit, ComponentCanDeactivate {
  @HostListener('window:beforeunload', ['$event'])
  canDeactivate(event): boolean {
    return event && this.updateLightsService.nextChangesComing() ? false : true;
  }

  schoolBuses: Array<Object>;
  commercialBuses: Array<Object>;
  edits: Subject<Object> = new Subject<Object>();
  addBusSubject: Subject<Object> = new Subject<Object>();
  date: any = new Date();
  newFolderNumber: number;
  grandTotalMoneySpent: number;
  grandTotalPurchasePrice: number;
  grandTotalEstimatedSalePrice: number;
  grandTotalRetailPrice: number;
  @ViewChildren(InvTableComponent) tables: QueryList<InvTableComponent>;
  @ViewChild('newToOldSwitch') newToOldSwitch: ElementRef;
  @ViewChild('sortBusNumSwitch') sortBusNumSwitch: ElementRef;
  sortNewToOld: boolean = false;
  sortBusNum: boolean = false;
  showBastard: boolean = false;

  // canDeactivate(): boolean {
  //   return !this.updateLightsService.nextChangesComing() && this.updateLightsService.pendingChanges === 0;
  // }

  addBusPipe = () => pipe(
    tap(() => this.updateLightsService.pendingChanges++),
    mergeMap(_ => this.inventoryService.addBus(_))
  );

  editPipe = () => pipe(
    tap(_ => this.updateLightsService.nextChanges[_["field"]] = true),
    groupBy(_ => "" + _["id"] + _["field"]),
    mergeMap(_ => _.pipe(
      distinctUntilChanged(),
      debounce(_ => _["nopause"] ? EMPTY : timer(1000)),
    )),
    tap(_ => {
      this.updateLightsService.nextChanges[_["field"]] = false;
      this.updateLightsService.pendingChanges++;
    }),
    mergeMap(_ => this.inventoryService.update(_["id"], _["field"], _["value"]))
  );

  subscriptionFunction = (result) => {
    this.updateLightsService.pendingChanges--;
    if(result) {
      this.newFolderNumber = result['folder_number'];

      if(result['folder_number'] || (result['field'] && result['field'] === 'sold')) {
        this.updateInventory();
      }
    }

    // Uncommenting this will cause the inventory list to automatically
    // re-order after updating, which will also have the effect of taking
    // focus away from the field being edited. I have left this feature out
    // so as to make editing the inventory list less potentially confusing.

    // this.updateInventory();
  }

  updateInventory(callback?: Function, doNotSort: boolean = false): void {
    this.inventoryService.commercialBuses.pipe(
      mergeMap(cb => {
        let commercialTable = this.tables.find(t => t.category === "commercial");
        if(this.newToOldSwitch["switchOn"] && !doNotSort) {
          cb.sort((a, b) => {
            return b["id"] - a["id"];
          });
        }
        else if(this.sortBusNumSwitch["switchOn"]) {
          cb.sort((a,b) => {
            let busNumA = a["bus_num"] ? a["bus_num"].toUpperCase() : 1;
            let busNumB = b["bus_num"] ? b["bus_num"].toUpperCase() : 1;
            return new Intl.Collator('en', {numeric: true}).compare(busNumA, busNumB);
          });
        }
        this.commercialBuses = cb;
        return this.inventoryService.schoolBuses;
      })
    )
    .subscribe(sb => {
      let schoolTable = this.tables.find(t => t.category === "school");
      if(this.newToOldSwitch["switchOn"] && !doNotSort) {
        sb.sort((a, b) => {
          return b["id"] - a["id"];
        });
      }
      else if(this.sortBusNumSwitch["switchOn"]) {
        sb.sort((a,b) => {
          let busNumA = a["bus_num"].toUpperCase();
          let busNumB = b["bus_num"].toUpperCase();
          return new Intl.Collator('en', {numeric: true}).compare(busNumA, busNumB);
        });
      }
      this.schoolBuses = sb;
      if(callback) {
        callback();
      }
    });
  }

  edit(event): void {
    this.edits.next(event);
  }

  addBus(event): void {
    this.addBusSubject.next(event);
  }

  print(fullSheet: boolean): void {
    let commercialTable = this.tables.find(t => t.category === "commercial");
    let schoolTable = this.tables.find(t => t.category === "school");

    this.updateInventory(() => {
        commercialTable.buses = this.commercialBuses;
        schoolTable.buses = this.schoolBuses;
        this.grandTotalMoneySpent = parseInt(schoolTable.total('money_spent')) + parseInt(commercialTable.total('money_spent'));
        this.grandTotalPurchasePrice = parseInt(schoolTable.total('purchase_price')) + parseInt(commercialTable.total('purchase_price'));
        this.grandTotalEstimatedSalePrice = parseInt(schoolTable.total('estimated_sale_price')) + parseInt(commercialTable.total('estimated_sale_price'));
        this.grandTotalRetailPrice = parseInt(schoolTable.total('retail_price')) + parseInt(commercialTable.total('retail_price'));

      // This SO answer explains why this setTimeout call is necessary:
      // https://stackoverflow.com/a/4575011. Bottom line: We must push the print
      // code to the end of the execution queue so that the Angular re-render
      // will run first, ensuring that any edited rows appear in their new
      // positions on the printed page.
      setTimeout(() => {
        let toggleableItems = document.getElementsByClassName("print-toggleable");
        for(let i = 0; i < toggleableItems.length; i++) {
          if(fullSheet) {
            toggleableItems[i].classList.remove("no-print");
          }
          else {
            toggleableItems[i].classList.add("no-print");
          }
        }
        let commercialTable = this.tables.find(t => t.category === "commercial");
        let schoolTable = this.tables.find(t => t.category === "school");

        window.print();
      }, 0);
    }, true);
  }

  refreshSort(): void {
    let schoolTable = this.tables.find(t => t.category === "school");
    let commercialTable = this.tables.find(t => t.category === "commercial");

    if(this.newToOldSwitch["switchOn"]) {
      schoolTable.sortNewestToOldest(true);
      commercialTable.sortNewestToOldest(true);
    }
  }

  toggleSortNewestToOldest(switchOn: boolean): void {
    let schoolTable = this.tables.find(t => t.category === "school");
    let commercialTable = this.tables.find(t => t.category === "commercial");

    if(switchOn) {this.sortBusNumSwitch["switchOn"] = false;}

    schoolTable.sortNewestToOldest(switchOn);
    commercialTable.sortNewestToOldest(switchOn);
  }

  toggleSortByBusNum(switchOn: boolean): void {
    let schoolTable = this.tables.find(t => t.category === "school");
    let commercialTable = this.tables.find(t => t.category === "commercial");

    if(switchOn) {this.newToOldSwitch["switchOn"] = false;}

    schoolTable.sortByBusNum(switchOn);
    commercialTable.sortByBusNum(switchOn);
  }


  constructor(
    private inventoryService: InventoryService,
    private updateLightsService: UpdateLightsService,
    private titleService: Title
  ) { }


  ngOnInit() {
    this.titleService.setTitle("Inventory List");
    this.inventoryService.schoolBuses.subscribe(sb => this.schoolBuses = sb);
    this.inventoryService.commercialBuses.subscribe(cb => this.commercialBuses = cb);
    this.edits.pipe(this.editPipe()).subscribe(this.subscriptionFunction);
    this.addBusSubject.pipe(this.addBusPipe()).subscribe(this.subscriptionFunction);

    window.addEventListener("afterprint", () => {
      this.refreshSort();
      // let schoolTable = this.tables.find(t => t.category === "school");
      // let commercialTable = this.tables.find(t => t.category === "commercial");
      //
      // if(schoolTable.sortNewToOld) {
      //   schoolTable.sortNewestToOldest(true);
      // }
      // if(commercialTable.sortNewToOld) {
      //   commercialTable.sortNewestToOldest(true);
      // }
    });
  }

}
