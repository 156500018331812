import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { environment } from '../../environments/environment';
import { Reminder } from '../reminder';

@Injectable({
  providedIn: 'root'
})
export class ReminderService {
  headers = {
    headers: new HttpHeaders({
      "Content-Type": "application/x-www-form-urlencoded"
    })
  };
  getRemindersApi: string = environment.apiRoot + "/reminders/get-reminders.php";
  addReminderApi: string = environment.apiRoot + "/reminders/add-reminder.php";
  markReminderDoneApi: string = environment.apiRoot + "/reminders/mark-reminder-done.php";

  getReminders(done: boolean = false, future: boolean = false, custid?: string): Observable<Reminder[]> {
    let queryStr = `?done=${done}&future=${future}`;
    if(custid) {
      queryStr += `&custid=${custid}`;
    }
    return this.http.get<Reminder[]>(this.getRemindersApi + queryStr).pipe(
      catchError(err => {
        console.log(err);
        return of([]);
      })
    )
  }

  addReminder(date: string, text: string, custid?: string): Observable<any> {
    let reminder = {
      date: date,
      text: text
    };
    if(custid) {reminder["custid"] = custid;}
    return this.http.post<void>(this.addReminderApi, JSON.stringify(reminder), this.headers).pipe(
      catchError(err => {
        console.log(err);
        return undefined;
      })
    );
  }

  markReminderDone(id: string): Observable<any> {
    return this.http.post<void>(this.markReminderDoneApi, `id=${id}`, this.headers).pipe(
      catchError(err => {
        console.log(err);
        return undefined;
      })
    );
  }

  constructor(private http: HttpClient) { }
}
