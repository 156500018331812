import { Component, OnInit, Input, Output, EventEmitter, ElementRef, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { AutolinkerService } from '../../services/autolinker.service';
import { Contact } from '../../contact';
import { environment } from '../../../environments/environment';

@Component({
  selector: 'app-contact-notes',
  templateUrl: './contact-notes.component.html',
  styleUrls: ['./contact-notes.component.scss']
})
export class ContactNotesComponent implements OnInit {
  @Input() notes: Array<Object>;
  initialNotesLoadComplete: boolean = false;
  @Input() vehImgUrl: string;
  @Input() vehid: string;
  @Input() contactid: string;
  @Output() closeNotes: EventEmitter<void> = new EventEmitter<void>();
  @ViewChild('customerNoteInput') customerNoteInput: ElementRef;

  placeholder: string;
  notesToDisplay: Array<Object> = [];
  @Output() newNote: EventEmitter<string> = new EventEmitter<string>();
  @Output() noteBoxTextChanged: EventEmitter<string> = new EventEmitter<string>();
  @Output() editNote: EventEmitter<string> = new EventEmitter<string>();
  environment = environment;

  addNote(event: any): void {
    if(event && event.shiftKey) {return;}
    event.preventDefault();
    let note = event.target["value"];

    event.target["value"] = "";
    let name = "customerNoteInput"+this.contactid;
    if(this.vehid) {name += "&"+this.vehid;}
    localStorage.removeItem(name);
    note = note.trim();
    if(!note) {return;}

    this.newNote.emit(note);
    this.noteBoxTextChanged.emit("");
  }

  performNoteEdit(textElement: HTMLElement, noteIndex: number): void {
    let removeContentEditable = (event) => {
      event.target.contentEditable = false;
      event.target.removeEventListener("blur", removeContentEditable);

      let noteId = this.notes[noteIndex]["id"];
      this.editNote.emit(noteId+"&"+event.target.textContent);
    };

    let blurTextElement = (event) => {
      if(event.key === "Enter" && !event.shiftKey) {
        event.preventDefault();
        event.target.textContent = event.target.textContent.trim();
        event.target.blur();
        event.target.removeEventListener("keyup", blurTextElement);
        event.target.removeEventListener("paste", pasteWithoutFormatting);
      }
    };

    let pasteWithoutFormatting = (event) => {
      event.preventDefault();
      let text = (event.originalEvent || event).clipboardData.getData("text/plain");
      document.execCommand("insertText", false, text);
    };

    textElement.addEventListener("blur", removeContentEditable);
    textElement.addEventListener("keyup", blurTextElement);
    textElement.addEventListener("paste", pasteWithoutFormatting);
    textElement.contentEditable = "true";
    textElement.focus();
    textElement.textContent = this.notes[noteIndex]["text"];
  }

  onNoteBoxTextChanged(noteBoxText: string): void {
    let name = "customerNoteInput"+this.contactid;
    if(this.vehid) {name += "&"+this.vehid;}
    localStorage.setItem(name, noteBoxText);
    this.noteBoxTextChanged.emit(noteBoxText);
  }

  refreshNotes(): void {
    setTimeout(() => {
      this.notesToDisplay = [];
      let notes = JSON.parse(JSON.stringify(this.notes));
      let noteTexts = [];
      for(let i = 0; i < notes.length; i++) {
        noteTexts.push(notes[i]["text"]);
      }

      let results = this.autolinkerService.autolink(noteTexts, this.elRef.nativeElement['localName']);

      new Promise(async resolve => {
        let linkedNotes: Array<Object> = [];
        for(let i = 0; i < results.length; i++) {
          let linkedNote = await results[i]();
          notes[i]["text"] = linkedNote["text"];
          notes[i]["links"] = linkedNote["links"];
          notes[i]["order"] = linkedNote["order"];
          linkedNotes.push(notes[i]);
        }
        resolve(linkedNotes);
      }).then(linkedNotes => {
        for(let i = 0; i < linkedNotes["length"]; i++) {
          this.notesToDisplay.push(linkedNotes[i]);
          this.autolinkerService.addLinkListeners(linkedNotes[i]["links"], this.elRef, this.router);
        }
        this.notesToDisplay.sort((a, b) => {
          return a["order"] - b["order"];
        });
      });
    }, 0);
  }

  userMayEdit(id: string): boolean {
    return id === localStorage.getItem("userid");
  }

  constructor(
    private autolinkerService: AutolinkerService,
    private elRef: ElementRef,
    private router: Router
  ) {
    // Need this to make sure the notes get loaded if you navigate from one
    // contact directly to another.
    this.router.routeReuseStrategy.shouldReuseRoute = () => false;
  }

  ngOnInit() {
    this.placeholder = "Add note";
    if(this.vehid) {
      this.placeholder += " for "+environment.vehicleName+" #"+this.vehid+"...";
    }
    else {
      this.placeholder += "...";
    }
    let storageItemName = "customerNoteInput"+this.contactid;
    if(this.vehid) {storageItemName += "&"+this.vehid;}
    this.customerNoteInput.nativeElement.value = localStorage.getItem(storageItemName);
    this.refreshNotes();
  }
}
