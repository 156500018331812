import { BrowserModule, Title } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { TitleCasePipe } from '@angular/common';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { AppComponent } from './app.component';
import { HeaderComponent } from './header/header.component';
import { SideNavComponent } from './side-nav/side-nav.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { AppRoutingModule } from './app-routing.module';
import { ContactSearchComponent } from './contact-search/contact-search.component';
import { ContactDataComponent } from './contact-data/contact-data.component';
import { PhonePipe } from './pipes/phone.pipe';
import { SideNavMobileComponent } from './side-nav-mobile/side-nav-mobile.component';
import { DisplayContactsTableComponent } from './display-contacts-table/display-contacts-table.component';
import { ShowAllContactsComponent } from './show-all-contacts/show-all-contacts.component';
import { CheckPipe } from './pipes/check.pipe';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';
import { ReminderPanelComponent } from './reminder-panel/reminder-panel.component';
import { BasicInfoTableComponent } from './contact-data/basic-info-table/basic-info-table.component';
import { TransactionStageComponent } from './contact-data/transaction-stage/transaction-stage.component';
import { VehiclesInterestedComponent } from './contact-data/vehicles-interested/vehicles-interested.component';
import { ContactNotesComponent } from './contact-data/contact-notes/contact-notes.component';
import { JwtModule } from '@auth0/angular-jwt';
import { ScratchpadComponent } from './dashboard/scratchpad/scratchpad.component';
import { UpdateLightsComponent } from './update-lights/update-lights.component';
import { PopupPanelComponent } from './popup-panel/popup-panel.component';
import { PopupComponentDirective } from './popup-component.directive';
import { VehicleDataComponent } from './vehicle-data/vehicle-data.component';
import { VehicleSearchComponent } from './vehicle-search/vehicle-search.component';
import { DisplayVehiclesTableComponent } from './display-vehicles-table/display-vehicles-table.component';
import { SliderSwitchComponent } from './slider-switch/slider-switch.component';
import { CategoriesDropdownComponent } from './vehicle-data/categories-dropdown/categories-dropdown.component';
import { PublishingComponent } from './vehicle-data/publishing/publishing.component';
import { BasicInfoComponent } from './vehicle-data/basic-info/basic-info.component';
import { MechanicalComponent } from './vehicle-data/mechanical/mechanical.component';
import { CategoriesComponent } from './vehicle-data/categories/categories.component';
import { AccommodationsComponent } from './vehicle-data/accommodations/accommodations.component';
import { WysiwygEditorComponent } from './wysiwyg-editor/wysiwyg-editor.component';
import { BypassHtmlPipe } from './pipes/bypass-html.pipe';
import { OwnerComponent } from './vehicle-data/owner/owner.component';
import { ImagesComponent } from './vehicle-data/images/images.component';
import { InventoryComponent } from './inventory/inventory.component';
import { InvTableComponent } from './inventory/inv-table/inv-table.component';
import { TaskerComponent } from './dashboard/tasker/tasker.component';
import { DatePipe } from '@angular/common';
import { DynamicLinkComponent } from './dynamic-link/dynamic-link.component';
import { InterestedContactsComponent } from './vehicle-data/interested-contacts/interested-contacts.component';
import { ClaimedByComponent } from './contact-data/claimed-by/claimed-by.component';
import { VehiclesOwnedComponent } from './contact-data/vehicles-owned/vehicles-owned.component';
import { VehicleSubmissionsComponent } from './vehicle-submissions/vehicle-submissions.component';
import { SuppliersComponent } from './suppliers/suppliers.component';
import { PurchasersComponent } from './purchasers/purchasers.component';
import { AutoconfirmatorComponent } from './autoconfirmator/autoconfirmator.component';
import { AvailabilityComponent } from './vehicle-data/availability/availability.component';
import { ClaimedNotUpdatedComponent } from './dashboard/claimed-not-updated/claimed-not-updated.component';
import { SupplierPurchaserStatusComponent } from './contact-data/supplier-purchaser-status/supplier-purchaser-status.component';
import { AddVehicleComponent } from './add-vehicle/add-vehicle.component';
import { AddContactComponent } from './add-contact/add-contact.component';
import { ContactFormSubmissionsComponent } from './contact-data/contact-form-submissions/contact-form-submissions.component';
import { VehicleContactNotesComponent } from './vehicle-data/vehicle-contact-notes/vehicle-contact-notes.component';
import { LoginService } from './services/login.service';
import { environment } from '../environments/environment';
import { DatePipeExtendedPipe } from './pipes/date-pipe-extended.pipe';
import { PendingChangesGuard } from './pending-changes.guard';

@NgModule({
  declarations: [
    AppComponent,
    HeaderComponent,
    SideNavComponent,
    DashboardComponent,
    ContactSearchComponent,
    ContactDataComponent,
    PhonePipe,
    SideNavMobileComponent,
    DisplayContactsTableComponent,
    ShowAllContactsComponent,
    CheckPipe,
    ReminderPanelComponent,
    BasicInfoTableComponent,
    TransactionStageComponent,
    VehiclesInterestedComponent,
    ContactNotesComponent,
    ScratchpadComponent,
    UpdateLightsComponent,
    PopupPanelComponent,
    PopupComponentDirective,
    VehicleDataComponent,
    VehicleSearchComponent,
    DisplayVehiclesTableComponent,
    SliderSwitchComponent,
    CategoriesDropdownComponent,
    PublishingComponent,
    BasicInfoComponent,
    MechanicalComponent,
    CategoriesComponent,
    AccommodationsComponent,
    WysiwygEditorComponent,
    BypassHtmlPipe,
    OwnerComponent,
    ImagesComponent,
    InventoryComponent,
    InvTableComponent,
    TaskerComponent,
    DynamicLinkComponent,
    InterestedContactsComponent,
    ClaimedByComponent,
    VehiclesOwnedComponent,
    VehicleSubmissionsComponent,
    SuppliersComponent,
    PurchasersComponent,
    AutoconfirmatorComponent,
    AvailabilityComponent,
    ClaimedNotUpdatedComponent,
    SupplierPurchaserStatusComponent,
    AddVehicleComponent,
    AddContactComponent,
    ContactFormSubmissionsComponent,
    VehicleContactNotesComponent,
    DatePipeExtendedPipe,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    FormsModule,
    InfiniteScrollModule,
    JwtModule.forRoot({
      config: {
        tokenGetter: tokenGetter,
        whitelistedDomains: ["10.1.10.133","10.1.10.133:8080","50.195.103.166:8080","www.busesforsale.com","old.busesforsale.com"],
        // BFS's webserver blocks normal Authorization header for whatever idiotic reason.
        // Since I was already modifying it, I figured I might as well get rid
        // of the useless word "Bearer" in the header, so it just contains
        // the jwt. That's what setting authScheme to the empty string does.
        headerName: "X-Authorization",
        authScheme: ""
      }
    })
  ],
  providers: [
    Title,
    TitleCasePipe,
    DatePipe,
    BypassHtmlPipe,
    PendingChangesGuard
    // {
    //   provide: HTTP_INTERCEPTORS,
    //   useClass: LoginService,
    //   multi: true
    // }
  ],
  bootstrap: [AppComponent],
  entryComponents: [DynamicLinkComponent]
})
export class AppModule { }
export function tokenGetter() {
  return localStorage.getItem("access_token");
}
