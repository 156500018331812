import { Component, OnInit, Input } from '@angular/core';
import { Router } from '@angular/router';
import { Contact } from '../../contact';
import { environment } from '../../../environments/environment';

@Component({
  selector: 'app-interested-contacts',
  templateUrl: './interested-contacts.component.html',
  styleUrls: ['./interested-contacts.component.scss']
})
export class InterestedContactsComponent implements OnInit {
  environment = environment;
  @Input() contacts: Array<Contact>;

  constructor(
    private router: Router
  ) { }

  ngOnInit() {

  }

}
