import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class MobileNavService {
  _navOpen: boolean = false;

  get navOpen(): boolean {
    return this._navOpen;
  }

  set navOpen(open: boolean) {
    if(open) {
      document.getElementsByClassName("router-box")[0]["style"].overflowY = "hidden";
      this._navOpen = true;
    }
    else {
      document.getElementsByClassName("router-box")[0]["style"].overflowY = "scroll";
      this._navOpen = false;
    }
  }

  constructor() { }
}
