import { Component, OnInit, Input } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { of } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { environment } from '../../../environments/environment';

@Component({
  selector: 'app-categories-dropdown',
  templateUrl: './categories-dropdown.component.html',
  styleUrls: ['./categories-dropdown.component.scss']
})
export class CategoriesDropdownComponent implements OnInit {
  @Input() categories: Array<string>;
  @Input() currentCategory: string;

  constructor(private http: HttpClient) { }

  ngOnInit() {

  }

}
