import { Component, OnInit, ViewChild } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { VehicleService } from '../services/vehicle.service';
import { DisplayVehiclesTableComponent } from '../display-vehicles-table/display-vehicles-table.component';
import { Vehicle } from '../vehicle';
import { environment } from '../../environments/environment';

@Component({
  selector: 'app-add-vehicle',
  templateUrl: './add-vehicle.component.html',
  styleUrls: ['./add-vehicle.component.scss']
})
export class AddVehicleComponent implements OnInit {
  environment = environment;
  title: string = "";
  submitButtonClicked: boolean = false;
  drafts: Array<Vehicle> = [];
  showDraftsHeader: boolean = true;
  @ViewChild(DisplayVehiclesTableComponent) table: DisplayVehiclesTableComponent;

  submit(): void {
    if(this.submitButtonClicked) {return;}
    this.submitButtonClicked = true;
    this.vehicleService.createNewVehicle(this.title)
    .subscribe(newid => this.router.navigateByUrl("/vehicledata/"+newid));
  }

  constructor(
    private vehicleService: VehicleService,
    private router: Router,
    private titleService: Title
  ) { }

  ngOnInit() {
    this.titleService.setTitle("Add New "+this.environment.vehicleName.charAt(0).toUpperCase()+this.environment.vehicleName.slice(1));
    this.table.loading = true;
    this.vehicleService.getDrafts().subscribe(vehs => {
      this.drafts = vehs;
      this.table.loading = false;
      if(!vehs.length) {
        this.showDraftsHeader = false;
      }
    });
  }

}
