import { Component, OnInit, ViewChild } from '@angular/core';
import { TitleCasePipe } from '@angular/common';
import { Title } from '@angular/platform-browser';
import { Subject, of } from 'rxjs';
import {
  debounceTime,
  switchMap,
  distinctUntilChanged,
  tap
} from 'rxjs/operators';
import { VehicleService } from '../services/vehicle.service';
import { DisplayVehiclesTableComponent } from '../display-vehicles-table/display-vehicles-table.component';
import { environment } from '../../environments/environment';

@Component({
  selector: 'app-vehicle-search',
  templateUrl: './vehicle-search.component.html',
  styleUrls: ['./vehicle-search.component.scss']
})
export class VehicleSearchComponent implements OnInit {
  environment = environment;
  searchTerms: Subject<string> = new Subject<string>();
  currentTerm: string;
  termHasChanged: boolean;
  offset: number = 0;
  @ViewChild(DisplayVehiclesTableComponent) vehiclesTable: DisplayVehiclesTableComponent;

  searchVehicles(term: string): void {
    this.currentTerm = term;
    this.offset = 0;
    this.termHasChanged = true;
    this.searchTerms.next(term);
  }

  onScroll(): void {
    this.offset += 20;
    this.termHasChanged = false;
    this.searchTerms.next(this.currentTerm);
  }

  constructor(
    private vehicleService: VehicleService,
    private titleService: Title,
    private titleCasePipe: TitleCasePipe
  ) { }

  ngOnInit() {
    this.titleService.setTitle(this.titleCasePipe.transform(this.environment.vehicleName) + " Search");
    this.searchTerms.pipe(
      debounceTime(400),
      tap(_ => {
        if(this.termHasChanged) {
          this.vehiclesTable.vehicles = [];
        }
        this.vehiclesTable.loading = true;
      }),
      switchMap(searchTerm => this.vehicleService.searchVehicles(searchTerm, this.offset))
    ).subscribe(response => {
      if(response && response.length > 0) {
        let resultWord = response.length === 1 ? "Result" : "Results";
        let resultCount = response.length + this.offset;
        this.titleService.setTitle(resultCount + " "+resultWord+" - " + this.titleCasePipe.transform(this.environment.vehicleName) + " Search");
      }
      else {
        this.titleService.setTitle(this.titleCasePipe.transform(this.environment.vehicleName) + " Search");
      }
      if(response) {
        for(let i = 0; i < response.length; i++) {
          this.vehiclesTable.vehicles.push(response[i]);
        }
      }
      this.vehiclesTable.loading = false;
    });
  }
}
