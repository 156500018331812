import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class TasksService {
  getUserListApi: string = environment.apiRoot + "/getusers.php";
  getTasksApi: string = environment.apiRoot + "/tasks/gettasks.php";
  assignTasksApi: string = environment.apiRoot + "/tasks/assigntasks.php";
  removeTasksApi: string = environment.apiRoot + "/tasks/removetask.php";

  postHeaders = {
    headers: new HttpHeaders({
      "Content-Type": "application/x-www-form-urlencoded"
    })
  };

  get userList(): Observable<Array<Object>> {
    return this.http.get<Array<Object>>(this.getUserListApi).pipe(
      catchError(error => {
        console.log(error);
        return of([""]);
      })
    );
  }

  getTasks(type: string): Observable<Array<string>> {
    return this.http.get<Array<string>>(this.getTasksApi + `?type=${type}`).pipe(
      catchError(error => {
        console.log(error);
        return of([""]);
      })
    );
  }

  assignTask(assignTo: string, task: string): Observable<any> {
    return this.http.post<void>(this.assignTasksApi, `assignTo=${assignTo}&task=${task}`, this.postHeaders).pipe(
      catchError(error => {
        console.log(error);
        return of([""]);
      })
    );
  }

  removeTask(id: string): Observable<any> {
    return this.http.post<Array<string>>(this.removeTasksApi, `taskid=${id}&action=delete`, this.postHeaders).pipe(
      catchError(error => {
        console.log(error);
        return of([""]);
      })
    );
  }

  markTaskDone(id: string): Observable<any> {
    return this.http.post<Array<string>>(this.removeTasksApi, `taskid=${id}&action=markdone`, this.postHeaders).pipe(
      catchError(error => {
        console.log(error);
        return of([""]);
      })
    );
  }

  constructor(private http: HttpClient) { }
}
