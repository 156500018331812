import { Component, OnInit, Input, Output, EventEmitter} from '@angular/core';
import { Router } from '@angular/router';
import { Subject } from 'rxjs';
import { Vehicle } from '../vehicle';
import { environment } from '../../environments/environment';

@Component({
  selector: 'app-display-vehicles-table',
  templateUrl: './display-vehicles-table.component.html',
  styleUrls: ['./display-vehicles-table.component.scss']
})
export class DisplayVehiclesTableComponent implements OnInit {
  @Input() vehicles: Vehicle[] = [];
  @Input() loading: boolean = false;
  @Output() scrolled: EventEmitter<any> = new EventEmitter<any>();
  @Input() lastVehId: string = "0";
  environment = environment;

  openVehicle(event: any, id: string): void {
    if(event["altKey"]) {
      window.open("/vehicledata/"+id, "_blank");
    }
    else {
      this.router.navigateByUrl("/vehicledata/"+id);
    }
  }

  onScroll(): void {
    this.scrolled.emit();
  }

  getRouter(): Router {
    return this.router;
  }

  constructor(
    private router: Router
  ) { }

  ngOnInit() {
  }
}
